import Api from '../common/Api';
import { DefaultResponse } from '../common/Api.types';
import * as Types from './StoreApis.types';

const apis = new Api('/api/stores');

export const getStore = async (
    request: Types.GetStoreRequest,
): Promise<DefaultResponse<Types.GetStoreResponse>> => {
    return await apis.get<Types.GetStoreRequest, Types.GetStoreResponse>(
        'getStore',
        request,
    );
};

export const getStores = async (
    request: Types.GetStoresRequest[],
): Promise<Types.GetStoresResponse> => {
    const responses = await Promise.all(
        request.map((item) => getStore({ storeId: item.storeId })),
    );
    const results: Types.GetStoresResponse = [];
    const items = responses
        .filter((response) => response[0] != null)
        .map((response) => response[0]);
    items.forEach((item) => {
        if (item) {
            results.push(item);
        }
    });
    return results;
};

export const getStoreSoldItems = async (
    request: Types.GetStoreSoldItemsRequest,
): Promise<DefaultResponse<Types.GetStoreSoldItemsResponse>> => {
    return await apis.post<
        Types.GetStoreSoldItemsRequest,
        Types.GetStoreSoldItemsResponse
    >('getStoreSoldItems', request);
};

export const exportAllItems = async (
    request: Types.ExportAllItemsRequest,
): Promise<DefaultResponse<Types.ExportAllItemsResponse>> => {
    return await apis.post<
        Types.ExportAllItemsRequest,
        Types.ExportAllItemsResponse
    >('exportAllItems', request);
};

export const exportSelectedItems = async (
    request: Types.ExportSelectedItemsRequest,
): Promise<DefaultResponse<Types.ExportSelectedItemsResponse>> => {
    return await apis.post<
        Types.ExportSelectedItemsRequest,
        Types.ExportSelectedItemsResponse
    >('exportSelectedItems', request);
};
