import Api from '../common/Api';
import { DefaultResponse } from '../common/Api.types';
import * as Types from './AccountApis.types';

const apis = new Api('/api/account');

export const getAccountStores = async (
    fakeUser: string | null,
): Promise<DefaultResponse<Types.GetAccountStoresResponse>> => {
    if (fakeUser) {
        return await apis.get<
            Types.GetAccountStoresRequest,
            Types.GetAccountStoresResponse
        >('getUserSellers', { fakeUser: fakeUser });
    } else {
        return await apis.get<
            Types.GetAccountStoresRequest,
            Types.GetAccountStoresResponse
        >('getUserSellers');
    }
};

export const addStoreToAccount = async (
    request: Types.AddStoreToAccountRequest,
): Promise<DefaultResponse<Types.AddStoreToAccountResponse>> => {
    return await apis.post<
        Types.AddStoreToAccountRequest,
        Types.AddStoreToAccountResponse
    >('addSellerToUser', request);
};

export const updateStoreMemo = async (
    request: Types.UpdateStoreMemoRequest,
): Promise<DefaultResponse<Types.UpdateStoreMemoResponse>> => {
    return await apis.post('updateStoreMemo', request);
};

export const removeStoreFromAccount = async (
    request: Types.RemoveStoreFromAccountRequest,
): Promise<DefaultResponse<Types.RemoveStoreFromAccountResponse>> => {
    return await apis.delete('removeSellerFromUser', request);
};
