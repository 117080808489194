import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Nav, Button, Spinner } from 'react-bootstrap';
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import './StorePage.css';
import { CSVLink } from 'react-csv';
import StoreTable from '../../components/store_table/StoreTable';
import {
    ExportAllItemsResponse,
    ExportSelectedItemsResponse,
    Filters,
    Item,
} from 'apis/store/StoreApis.types';
import { headers } from './StoreTableToolkit.consts';
import { useQuery } from '@tanstack/react-query';
import { exportAllItems, exportSelectedItems } from 'apis';
import { exportData } from './exportCSV';

interface StoreTableToolkitProps {
    numberOfItems: number;
    data: Item[];
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setLimit: React.Dispatch<React.SetStateAction<number>>;
    setSort: React.Dispatch<
        React.SetStateAction<{ sortOrder: 'asc' | 'desc'; sortField: string }>
    >;
    isLoading: boolean;
    storeId?: string;
    filters: Filters;
}

const StoreTableToolkit: React.FC<StoreTableToolkitProps> = (props) => {
    const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
    const [exportAllPressed, setExportAllPressed] = useState<boolean>(false);
    const [exportSelectedPressed, setSelectedPressed] =
        useState<boolean>(false);
    const downloadSelectedCSVLink = useRef<any>(null);
    const downloadAllCSVLink = useRef<any>(null);
    const exportAll = useQuery<ExportAllItemsResponse, Error>(
        ['all-store-items', props.storeId ?? ''],
        async () => {
            if (props.storeId) {
                const [result, error] = await exportAllItems({
                    storeId: props.storeId,
                    domain: 'ebay',
                    filters: props.filters,
                });
                if (result) {
                    return result;
                }
                throw new Error(
                    error?.data?.message ?? 'An unknown error occurred',
                );
            }
            throw new Error('Store id is not defined');
        },
        {
            enabled: false,
            refetchOnWindowFocus: false,
            initialData: [],
        },
    );
    useEffect(() => {
        if (
            !exportAll.isRefetching &&
            exportAll.isSuccess &&
            exportAll.isFetched &&
            exportAllPressed
        ) {
            downloadAllCSVLink?.current?.link?.click();
            setExportAllPressed(false);
        }
    }, [
        exportAll.data,
        exportAll.isRefetching,
        exportAll.isSuccess,
        exportAll.isFetched,
    ]);
    const exportSelected = useQuery<ExportSelectedItemsResponse, Error>(
        ['selected-store-items', props.storeId ?? ''],
        async () => {
            if (props.storeId) {
                const [result, error] = await exportSelectedItems({
                    storeId: props.storeId,
                    domain: 'ebay',
                    itemIds: [...selectedItems],
                });
                if (result) {
                    return result;
                }
                throw new Error(
                    error?.data?.message ?? 'An unknown error occurred',
                );
            }
            throw new Error('Store id is not defined');
        },
        {
            enabled: false,
            refetchOnWindowFocus: false,
            initialData: [],
        },
    );
    useEffect(() => {
        if (
            !exportSelected.isRefetching &&
            exportSelected.isSuccess &&
            exportSelected.isFetched &&
            exportSelectedPressed
        ) {
            downloadSelectedCSVLink?.current?.link?.click();
            setSelectedPressed(false);
        }
    }, [
        exportSelected.data,
        exportSelected.isRefetching,
        exportSelected.isSuccess,
        exportSelected.isFetched,
    ]);
    return (
        <ToolkitProvider
            keyField="item_id"
            bootstrap4
            exportCSV={{
                exportAll: false,
                onlyExportSelection: true,
            }}
            data={props.data}
            columns={headers}
        >
            {(tableProps) => (
                <PaginationProvider
                    pagination={paginationFactory({
                        custom: true,
                        totalSize: props.numberOfItems,
                        showTotal: true,
                        paginationTotalRenderer: (
                            from: any,
                            to: any,
                            size: any,
                        ) => (
                            <span className="react-bootstrap-table-pagination-total">
                                {size}アイテムのうち{from}〜{to}
                                アイテムを表示中
                            </span>
                        ),
                    })}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <Row>
                            <Row>
                                <Col>
                                    <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                    />
                                    <Button
                                        onClick={() => {
                                            setSelectedPressed(true);
                                            exportSelected.refetch();
                                        }}
                                        style={{
                                            marginLeft: '2px',
                                            borderColor: 'rgba(67,193,168,255)',
                                            backgroundColor:
                                                'rgba(67,193,168,255)',
                                            color: 'white',
                                        }}
                                        variant="info"
                                        disabled={exportSelected.isRefetching}
                                    >
                                        {exportSelected.isFetching && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{ marginRight: '6px' }}
                                            />
                                        )}
                                        CSVエクスポート
                                    </Button>
                                    <CSVLink
                                        asyncOnClick
                                        data={exportData(exportSelected.data)}
                                        className="hidden"
                                        target="_blank"
                                        ref={downloadSelectedCSVLink}
                                    ></CSVLink>
                                    <Button
                                        onClick={() => {
                                            setExportAllPressed(true);
                                            exportAll.refetch();
                                        }}
                                        disabled={exportAll.isFetching}
                                        variant="info"
                                        style={{
                                            backgroundColor:
                                                'rgba(79,164,223,255)',
                                            borderColor: 'rgba(79,164,223,255)',
                                            color: 'white',
                                        }}
                                    >
                                        {exportAll.isFetching && (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{ marginRight: '6px' }}
                                            />
                                        )}
                                        ALL CSVエクスポート
                                    </Button>
                                    <CSVLink
                                        asyncOnClick
                                        data={exportData(exportAll.data)}
                                        className="hidden"
                                        target="_blank"
                                        ref={downloadAllCSVLink}
                                    ></CSVLink>
                                </Col>
                                <Col></Col>
                                <Col>
                                    <Col>
                                        <div
                                            style={{
                                                float: 'right',
                                            }}
                                        >
                                            <PaginationListStandalone
                                                {...paginationProps}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div
                                            style={{
                                                float: 'right',
                                                marginRight: '5px',
                                            }}
                                        >
                                            <PaginationTotalStandalone
                                                {...paginationProps}
                                            />
                                        </div>
                                    </Col>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    overflowY: 'scroll',
                                    maxHeight: '1000px',
                                }}
                            >
                                <StoreTable
                                    paginationTableProps={paginationTableProps}
                                    baseProps={tableProps.baseProps}
                                    setPage={props.setPage}
                                    setLimit={props.setLimit}
                                    setSort={props.setSort}
                                    isLoading={props.isLoading}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems}
                                />
                            </Row>
                        </Row>
                    )}
                </PaginationProvider>
            )}
        </ToolkitProvider>
    );
};

export default StoreTableToolkit;
