import React, { useContext, useState } from 'react';
import { Navbar, Col, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { AccountContext } from 'hooks';
import './Navigation.css';

export const Navigation: React.FC<React.PropsWithChildren<unknown>> = (
    props,
) => {
    const [hovered, setHovered] = useState<boolean[]>([false, false, false]);
    const { user } = useContext(AccountContext);

    return (
        <Navbar
            style={{
                backgroundColor: 'rgba(237,237,237,255)',
                minHeight: '57px',
                maxHeight: '57px',
                borderBottom: '1px solid rgba(217,222,228,255)',
                fontFamily: 'Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif',
            }}
        >
            <Col>{props.children}</Col>
            <Col
                style={{
                    backgroundColor: hovered[0] ? 'rgba(217,222,228)' : '',
                    width: '78px',
                    maxWidth: '78px',
                    height: '100%',
                }}
                onMouseEnter={() => setHovered([true, false, false])}
                onMouseLeave={() => setHovered([false, false, false])}
            >
                <Nav.Link
                    href="https://ws.formzu.net/fgen/S97851601/"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        marginTop: '10px',
                        textAlign: 'center',
                        width: '100%',
                        padding: '0px',
                        color: '#515356',
                        float: 'right',
                        textDecoration: 'none',
                        fontSize: '13px',
                    }}
                >
                    お問い合わせ
                </Nav.Link>
            </Col>
            {user && user.name !== '' && (
                <Col
                    style={{
                        width: '134px',
                        maxWidth: '134px',
                        height: '100%',
                        backgroundColor: hovered[1] ? 'rgba(217,222,228)' : '',
                    }}
                    onMouseEnter={() => setHovered([false, true, false])}
                    onMouseLeave={() => setHovered([false, false, false])}
                >
                    <NavDropdown
                        title={`${user.name}さん`}
                        align="end"
                        className="navDropdownText"
                        style={{
                            color: '#515356',
                            fontFamily:
                                'Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif',
                            fontSize: '13px',
                        }}
                    >
                        <b style={{ marginLeft: '2px', fontSize: '65%' }}>
                            ユーザー設定
                        </b>
                        <NavDropdown.Item
                            href="https://ums.saatscommerce.jp/users/change_password"
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontSize: '12px' }}
                        >
                            パスワード変更
                        </NavDropdown.Item>
                        <NavDropdown.Item
                            href="https://ums.saatscommerce.jp/users/change_email"
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontSize: '12px' }}
                        >
                            Eメールアドレス変更
                        </NavDropdown.Item>
                        <hr />
                        <NavDropdown.Item
                            href="https://ums.saatscommerce.jp/Plans/select_plans/sa"
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontSize: '12px' }}
                        >
                            プラン変更＆解約
                        </NavDropdown.Item>
                        <hr />
                        <NavDropdown.Item>
                            <NavLink
                                style={{
                                    textDecoration: 'none',
                                    color: '#515356',
                                    fontSize: '12px',
                                }}
                                to="/logout"
                            >
                                ログアウト
                            </NavLink>
                        </NavDropdown.Item>
                    </NavDropdown>
                </Col>
            )}
        </Navbar>
    );
};
