import React, { createContext, useContext, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

type LMEModalContextType = {
    showModal: (modalProps?: ModalProps) => void;
    hideModal: () => void;
};

export const LMEModalContext = createContext<LMEModalContextType>({
    showModal: () => {
        throw new Error('NOT IMPLEMENTED');
    },
    hideModal: () => {
        throw new Error('NOT IMPLEMENTED');
    },
});

export const LMEModalProvider: React.FC<{ children: React.ReactNode }> = (
    props,
) => {
    const [open, setOpen] = useState<boolean>(false);
    const [modalProps, setModalProps] = useState<ModalProps | undefined>();
    const showModal = (modalProps?: ModalProps) => {
        setOpen(true);
        setModalProps(modalProps);
    };
    const hideModal = () => {
        setOpen(false);
    };
    return (
        <LMEModalContext.Provider
            value={{
                showModal: showModal,
                hideModal: hideModal,
            }}
        >
            <Modal {...modalProps} show={open} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalProps?.title || ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    {modalProps?.children}
                </Modal.Body>
            </Modal>
            {props.children}
        </LMEModalContext.Provider>
    );
};

export function useModalContext() {
    return useContext(LMEModalContext);
}
