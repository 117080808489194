import React, { createContext, useContext, useEffect, useState } from 'react';
import * as apis from 'apis';
import { DefaultResponse, IsEbayConnectedResponse } from 'apis';
import { Navigate, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

type EbayContextType = {
    exchangeAccessCodeForToken: (
        request: apis.ExchangeAccessCodeForTokenRequest,
    ) => Promise<DefaultResponse<apis.ExchangeAccessCodeForTokenResponse>>;
    generateLoginURL: () => Promise<
        DefaultResponse<apis.ExchangeAccessCodeForTokenResponse>
    >;
    isEbayConnected: () => Promise<DefaultResponse<IsEbayConnectedResponse>>;
    ebayIsConnected: boolean;
    loading: boolean;
};

export const EbayContext = createContext<EbayContextType>({
    exchangeAccessCodeForToken: async () => [null, null],
    generateLoginURL: async () => [null, null],
    isEbayConnected: async () => [null, null],
    ebayIsConnected: false,
    loading: false,
});

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const EbayLogin: React.FC<{ loginURL: string }> = (props) => {
    window.location.href = props.loginURL;
    return <Navigate to="/home" replace />;
};

export const EbayProvider: React.FC<{ children: React.ReactNode }> = (
    props,
) => {
    const query = useQuery();
    const code = query.get('code');
    const type = query.get('type');
    const [ebayIsConnected, setEbayIsConnected] = useState<boolean>(false);
    const [stateUpdateWaiting, setStateUpdateWaiting] =
        useState<boolean>(false);
    const [redirectURL, setRedirectURL] = useState<string>('');
    const exchangeAccessCodeForToken = async (
        request: apis.ExchangeAccessCodeForTokenRequest,
    ): Promise<DefaultResponse<apis.ExchangeAccessCodeForTokenResponse>> => {
        return await apis.exchangeAccessCodeForToken(request);
    };
    const generateLoginURL = async (): Promise<
        DefaultResponse<apis.GenerateLoginURLResponse>
    > => {
        return await apis.generateLoginURL();
    };
    const isEbayConnected = async (): Promise<
        DefaultResponse<IsEbayConnectedResponse>
    > => {
        setStateUpdateWaiting(true);
        const [result, error] = await apis.isEbayConnected();
        if (result) {
            setEbayIsConnected(result.connected);
            if (!result.connected) {
                setStateUpdateWaiting(false);
            }
        } else {
            setEbayIsConnected(false);
            setStateUpdateWaiting(false);
        }
        return [result, error];
    };
    useEffect(() => {
        if (ebayIsConnected) {
            setStateUpdateWaiting(false);
        }
    }, [ebayIsConnected]);
    useEffect(() => {
        isEbayConnected().then((response) => {
            const [result] = response;
            if (result && result.connected) {
                setEbayIsConnected(true);
            } else {
                if (!ebayIsConnected && (code == null || type == null)) {
                    setStateUpdateWaiting(true);
                    generateLoginURL().then((response) => {
                        const [result] = response;
                        if (result) {
                            setRedirectURL(result);
                            setStateUpdateWaiting(false);
                        }
                    });
                } else if (!ebayIsConnected && code != null && type != null) {
                    setStateUpdateWaiting(true);
                    exchangeAccessCodeForToken({ code: code }).then(
                        (response) => {
                            const [result] = response;
                            if (result && result === 'OK') {
                                setStateUpdateWaiting(false);
                            }
                        },
                    );
                }
            }
        });
    }, []);
    return (
        <EbayContext.Provider
            value={{
                exchangeAccessCodeForToken: exchangeAccessCodeForToken,
                generateLoginURL: generateLoginURL,
                isEbayConnected: isEbayConnected,
                ebayIsConnected: ebayIsConnected,
                loading: stateUpdateWaiting,
            }}
        >
            {!stateUpdateWaiting && !ebayIsConnected && redirectURL !== '' && (
                <EbayLogin loginURL={redirectURL} />
            )}
            {!stateUpdateWaiting && ebayIsConnected && props.children}
            {stateUpdateWaiting && (
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )}
        </EbayContext.Provider>
    );
};

export function useEbayContext() {
    return useContext(EbayContext);
}
