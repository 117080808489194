import {
    addStoreToAccount,
    getAccountStores,
    removeStoreFromAccount,
    getStores,
} from 'apis';

export async function retrieveUserStores(fakeUser: string | null) {
    let tempStores: {
        storeId: string;
        storeName: string;
        soldItemsCount: number;
        lastUpdatedOn: Date;
        averagePrice: number;
    }[] = [];
    const [result, error] = await getAccountStores(fakeUser);
    if (result) {
        const tempStoreIds: { storeId: string; storeName: string }[] =
            result.stores;
        const stores = await getStores(
            tempStoreIds.map((store) => ({ storeId: store.storeId })),
        );
        if (stores.length > 0) {
            tempStores = tempStoreIds.map(
                (store: { storeId: string; storeName: string }) => {
                    const storeData = stores.find(
                        (curStore: any) => curStore.storeId === store.storeId,
                    );
                    return {
                        storeId: store.storeId,
                        storeName: store.storeName,
                        soldItemsCount: storeData?.soldItemsCount || 0,
                        lastUpdatedOn: new Date(
                            storeData?.lastUpdatedOn || new Date(),
                        ),
                        averagePrice: storeData?.averagePrice || 0,
                    };
                },
            );
        }
    } else {
        throw new Error(error?.data?.message);
    }
    return tempStores;
}

export async function addStore(
    storeId: string,
    storeName: string,
): Promise<undefined | any> {
    return await addStoreToAccount({
        storeId: storeId,
        storeName: storeName,
    });
}

export async function removeStore(storeId: string): Promise<any[]> {
    return await removeStoreFromAccount({ storeId: storeId });
}
