import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { NavLink, Navigate } from 'react-router-dom';
import { addSystemMessage, DefaultResponse, getAllStores, GetAllStoresResponse, getAllUsers, GetAllUsersResponse, removeSystemMessage } from 'apis';
import { AccountContext } from 'hooks';
import { Layout } from 'ui';

const AdminPage: React.FC = () => {
    const [message, setMessage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const { isAdmin } = useContext(AccountContext);
    const [error, setError] = useState<string>('');
    const [accounts, setAccounts] = useState<any[]>([]);
    const [stores, setStores] = useState<any[]>([]);
    useEffect(() => {
        if (isAdmin) {
            getAllUsers().then((response: DefaultResponse<GetAllUsersResponse>) => {
                const [result] = response;
                if (result) {
                    setAccounts(result.filter((a: any) => a.User !== undefined).sort((a: any, b: any) => {
                        if (a.User === undefined || b.User === undefined) {
                            return a.User === undefined ? b.User.localeCompare('') : a.User.localeCompare('');
                        }
                        return a.User.localeCompare(b.User);
                    }));
                }
            });
            getAllStores().then((response: DefaultResponse<GetAllStoresResponse>) => {
                const [result] = response;
                if (result) {
                    setStores(result.sort((a: any, b: any) => a.storeId.localeCompare(b.storeId)));
                }
            });
        }
    }, []);
    const onSubmit = () => {
        setLoading(true);
        addSystemMessage({ message: message }).then((response: any) => {
            setLoading(false);
            setMessage('');
        }).catch((error: any) => {
            setLoading(false);
            setMessage('');
            console.error(error);
            setError(`Error adding a new message to the system ${error}`);
        });
    }
    const onDelete = () => {
        setLoading(true);
        removeSystemMessage().then((response: any) => {
            setLoading(false);
        }).catch((error: any) => {
            console.error(error);
            setLoading(false);
            setError(`Error deleting the system message ${error}`);
        });
    }
    if (!isAdmin) {
        return (
            <Navigate to='/home' replace />
        )
    }
    return (
        <Layout collapsedWidth={825} noDisplayWidth={1}>
            <Card style={{ fontFamily: 'Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif' }}>
                <Card.Header>System Message</Card.Header>
                <Card.Body>
                    {
                        error !== '' &&
                        <Alert variant="danger">
                            <Alert.Heading>
                                System Error
                            </Alert.Heading>
                            <p>{error}</p>
                        </Alert>
                    }
                    <Form>
                        <Form.Group>
                            <Row style={{ marginBottom: '8px' }}>
                                <Form.Control disabled={loading} type='text' value={message} onChange={e => setMessage(e.target.value)} />
                            </Row>
                            <Row xs={8}>
                                <Col xs={4}>
                                    <Button onClick={e => onSubmit()} disabled={loading} style={{ marginRight: '10px' }}>Submit</Button>
                                    <Button onClick={e => onDelete()} disabled={loading} variant="danger">Delete</Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
            <Card style={{ fontFamily: 'Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif' }}>
                <Card.Header>View User Account</Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Row style={{ marginBottom: '8px' }}>
                                <Dropdown>
                                    <Dropdown.Toggle variant='secondary'>View User Account</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div style={{ height: '500px', overflowY: 'scroll' }}>
                                            {accounts.map((account, index) => (
                                                <Dropdown.Item key={index} as={NavLink} to={`/home?fakeUser=${account.User}`}>{account.User}</Dropdown.Item>
                                            ))
                                            }
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Row>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
            <Card style={{ fontFamily: 'Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif' }}>
                <Card.Header>View Store</Card.Header>
                <Card.Body>
                    <Form>
                        <Form.Group>
                            <Row style={{ marginBottom: '8px' }}>
                                <Dropdown>
                                    <Dropdown.Toggle variant='secondary'>View Store</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div style={{ height: '500px', overflowY: 'scroll' }}>
                                            {stores.map((store, index) => (
                                                <Dropdown.Item key={index} as={NavLink} to={`/admin/${store.storeId}`}>{store.storeId}</Dropdown.Item>
                                            ))
                                            }
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Row>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        </Layout>
    );
}

export default AdminPage;