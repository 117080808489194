import { UseMutateFunction } from '@tanstack/react-query';
import { Button, Col, Nav, Row } from 'react-bootstrap';
import { VscEdit } from 'react-icons/vsc';
import { NavLink } from 'react-router-dom';
import { strings } from '../../locales/locales';

export const headers = (
    fakeUser: string | null,
    removeStorePost: UseMutateFunction<any, unknown, string, unknown>,
) => [
    {
        dataField: 'storeId',
        text: strings.ebayStoreIdColumnTitle,
        headerStyle: { color: '#73879C' },
        editable: false,
        formatter: (cell) => (
            <Nav.Link
                href={`https://www.ebay.com/sch/${cell}/m.html?_nkw&_armrs=1&_from&LH_Complete=1&LH_Sold=1&rt=nc&LH_ItemCondition=3`}
                target="_blank"
                rel="noreferrer"
            >
                {cell}
            </Nav.Link>
        ),
    },
    {
        dataField: 'storeName',
        headerStyle: { color: '#73879C' },
        text: strings.ebayStoreNoteColumnTitle,
        formatter: (cell) => (
            <span>
                <VscEdit /> {cell}
            </span>
        ),
    },
    {
        dataField: 'soldItemsCount',
        headerStyle: { color: '#73879C' },
        editable: false,
        text: strings.soldItemsColumnTitle,
    },
    {
        dataField: 'averagePrice',
        headerStyle: { color: '#73879C' },
        editable: false,
        text: strings.ebayStoreItemAveragePriceColumnTitle,
        formatter: (cell) => Math.round(parseFloat(cell) * 100) / 100,
    },
    {
        formatter: (cell) =>
            `${cell.toISOString().split('T')[0]} ${cell
                .toISOString()
                .split('T')[1]
                .substr(0, 5)} UTC`,
        dataField: 'lastUpdatedOn',
        headerStyle: { color: '#73879C' },
        editable: false,
        text: strings.ebayStoreLastUpdatedOnColumnTitle,
    },
    {
        dataField: 'actions',
        headerStyle: { color: '#73879C' },
        editable: false,
        text: strings.ebayStoreItemListColumnTitle,
        formatter: (cell: any, row: any) => {
            return (
                <Row>
                    <Col>
                        <NavLink
                            to={
                                fakeUser
                                    ? `/home/${row.storeId}?fakeUser=${fakeUser}`
                                    : `/home/${row.storeId}`
                            }
                        >
                            {strings.viewStoreItemsButton}
                        </NavLink>
                    </Col>
                    <Col>
                        <Button
                            style={{
                                width: '120px',
                                backgroundColor: 'rgba(236,98,84,255)',
                                borderColor: 'rgba(236,98,84,255)',
                            }}
                            onClick={() => removeStorePost(row.storeId)}
                            variant="danger"
                        >
                            {strings.removeStoreRowButtonText}
                        </Button>
                    </Col>
                </Row>
            );
        },
    },
];
