export const exportData = (selectedRows: any[]): Array<Array<any>> => {
    const results: Array<Array<any>> = [];

    results.push([
        'SKU',
        'eBayアイテムID',
        '商品タイトル',
        'カテゴリー',
        '商品説明',
        '重量 (ｇ)',
        '仕入価格',
        '状態',
        '在庫監視',
        '引用元URL',
        '固定 価格',
        '数量',
        '数量固定',
        '発送方法（EMS）',
        '送料ポリシー',
        '補正値($)',
        '販売手数料(率)',
        '利益率',
        '最低利益',
        '高さ(mm)',
        '長さ(mm)',
        '幅(mm)',
    ]);
    for (let i = 0; i < 12; i++) {
        results[0].push(`画像 URL ${i + 1}`);
    }
    results[0].push('ブランド', 'UPC', 'MPN');
    for (let i = 0; i < 20; i++) {
        results[0].push(`名称${i + 1}`, `値${i + 1}`);
    }
    results[0].push(
        '属性１',
        'オプション１',
        '属性２',
        'オプション２',
        '属性３',
        'オプション３',
        'ベスト・オファー設定',
        '自動承認',
        '最低価格',
        'この価格以下なら却下',
        'プライベート出品',
        '状態説明',
    );
    results[0].push('Price', 'Domestic Shipping Price');

    selectedRows.forEach((item: any) => {
        const brand =
            item.item_specifics.length === 0
                ? ''
                : item.item_specifics.find(
                      (element: any) => element.Name === 'Brand',
                  )?.Value || '';
        const pictures =
            item.pictures?.PictureURL !== undefined
                ? typeof item.pictures?.PictureURL === 'string'
                    ? [item.pictures?.PictureURL]
                    : item.pictures?.PictureURL
                : [];
        while (pictures.length < 12) {
            pictures.push('');
        }
        const specifics: string[] = [];
        for (
            let i = 0;
            i < Math.min(20, item.item_specifics?.length ?? 0);
            i++
        ) {
            specifics.push(
                item.item_specifics[i].Name,
                item.item_specifics[i].Value,
            );
        }
        while (specifics.length < 40) {
            specifics.push('', '');
        }
        //item.description?.join(' ') ?? '',
        results.push(
            [
                item.sku || '',
                item.item_id,
                item.title,
                item?.primary_category?.CategoryID || '',
                item.title,
                (item?.shipping_package_details?.WeightMajor?.value || '') +
                    (item?.shipping_package_details?.WeightMajor?._unit || '') +
                    (item?.shipping_package_details?.WeightMinor?.value || '') +
                    (item?.shipping_package_details?.WeightMinor?._unit || ''),
                '',
                item?.condition_id || '',
                '',
                `https://www.amazon.co.jp/dp/${item.asin}`,
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                (item?.shipping_package_details?.PackageDepth?.value || '') +
                    (item?.shipping_package_details?.PackageDepth?._unit || ''),
                (item?.shipping_package_details?.PackageLength?.value || '') +
                    (item?.shipping_package_details?.PackageLength?._unit ||
                        ''),
                (item?.shipping_package_details?.PackageWidth?.value || '') +
                    (item?.shipping_package_details?.PackageWidth?._unit || ''),
                pictures,
                brand,
                item.upc === ''
                    ? ''
                    : item.upc === 'Does not apply' || item.upc === 'N/A'
                    ? ''
                    : item.upc || '',
                item.product_listing_details?.BrandMPN?.MPN || 'Does not apply',
                specifics,
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                item.price,
                item?.shipping_details?.ShippingServiceOptions
                    ?.ShippingServiceCost?.value || '',
            ].flat(),
        );
    });

    return results;
};
