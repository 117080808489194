import { Filters } from './FiltersCard.types';

export const defaultFilters: Filters = {
    format: '', //形式
    registrationStatus: '出品候補登録状況',
    janStatus: 'JAN取得状況',
    numberOfBidsLow: '',
    numberOfBidsHigh: '',
    productTitle: '',
    contractPriceLow: '',
    contractPriceHigh: '',
    endTimeStart: '',
    endTimeEnd: '',
    itemId: '',
};
