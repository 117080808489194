import { Filters } from 'apis';

export const search = (
    filters: { [index: string]: string },
    hasUpc: boolean,
    hasAsin: boolean,
    includeKeywords: string[],
    excludeKeywords: string[],
    setPage: React.Dispatch<React.SetStateAction<number>>,
    setFilters: React.Dispatch<React.SetStateAction<Filters>>,
) => {
    setPage(1);
    setFilters({
        upc: hasUpc,
        asin: hasAsin,
        format:
            filters.format === '形式'
                ? 'FixedPriceItem'
                : filters.format === 'オークション形式'
                ? 'Auction'
                : undefined,
        productTitle:
            filters.productTitle === '' ? undefined : filters.productTitle,
        contractPriceLow:
            filters.contractPriceLow === ''
                ? undefined
                : filters.contractPriceLow,
        contractPriceHigh:
            filters.contractPriceHigh === ''
                ? undefined
                : filters.contractPriceHigh,
        itemId: filters.itemId === '' ? undefined : filters.itemId,
        endTimeEnd: filters.endTimeEnd
            ? new Date(filters.endTimeEnd).toISOString()
            : undefined,
        endTimeStart: filters.endTimeStart
            ? new Date(filters.endTimeStart).toISOString()
            : undefined,
        excludedKeywords:
            excludeKeywords.length === 0 ? undefined : excludeKeywords,
        includedKeywords:
            includeKeywords.length === 0 ? undefined : includeKeywords,
    });
};

export const clearFilters = (
    setPage: React.Dispatch<React.SetStateAction<number>>,
    setFilters: React.Dispatch<React.SetStateAction<Filters>>,
) => {
    setPage(1);
    setFilters({});
};
