import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { getSystemMessage, GetSystemMessageResponse } from 'apis';
import { useQuery } from '@tanstack/react-query';

const ServerMessageAlert: React.FC = () => {
    const [showServerMessage, setShowServerMessage] = useState<boolean>(false);
    const { isLoading, isError, data, error } = useQuery<
        GetSystemMessageResponse,
        { message: string }
    >(['get-system-message'], async () => {
        const [result, error] = await getSystemMessage();
        if (result) {
            return result;
        }
        throw new Error(error?.data?.message);
    });
    if (isLoading || !data || data.message === '' || !showServerMessage)
        return <></>;
    if (isError) {
        return (
            <Alert
                style={{ marginTop: '4px' }}
                variant="danger"
                onClose={(e) => setShowServerMessage(false)}
                dismissible
            >
                <Alert.Heading>サーバーのエラー</Alert.Heading>
                <p>
                    {error.message ??
                        'Unable to retrieve the system message from the server'}
                </p>
            </Alert>
        );
    }
    return (
        <Alert
            style={{ marginTop: '4px' }}
            variant="success"
            onClose={(e) => setShowServerMessage(false)}
            dismissible
        >
            <Alert.Heading>サーバーのお知らせ</Alert.Heading>
            <p>{data.message}</p>
        </Alert>
    );
};

export default ServerMessageAlert;
