import {
    Route,
    useParams,
    useLocation
  } from "react-router-dom";
import AccountStoresPage from "../pages/account/AccountStoresPage";
import StorePage from "../pages/store/StorePage";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const AccountStoresWrapper: React.FC = () => {
  const query = useQuery();
  const fakeUser = query.get('fakeUser');
    return (
      <AccountStoresPage fakeUser={fakeUser}/>
    );
}

export const StoresWrapper: React.FC = () => {
  const { storeId } = useParams<{ storeId: string }>();
  const query = useQuery();
  const fakeUser = query.get('fakeUser');
  return (
    <StorePage storeId={storeId} fakeUser={fakeUser}/>
  )
}
