import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

interface ItemOverlayProps {
    row: any;
    cell: any;
}

const ItemOverlay: React.FC<ItemOverlayProps> = (props) => {
    const details = { ...props.row };
    const headers = Object.keys(details).filter(
        (col) =>
            props.row[col] !== '' &&
            col !== 'pictures' &&
            col !== '_id' &&
            typeof props.row[col] === 'string',
    );
    const titleIndex = headers.findIndex((header) => header === 'title');
    const temp = headers[1];
    headers[1] = headers[titleIndex];
    headers[titleIndex] = temp;
    const item_specific_names: string[] = [];
    const item_specific_values: string[] = [];
    details?.item_specifics?.forEach((specific) => {
        const name = specific['Name'];
        const value = specific['Value'];
        if (
            (name !== 'MPN' || !headers.find((element) => element === 'MPN')) &&
            (name !== 'UPC' || !headers.find((element) => element === 'upc'))
        ) {
            item_specific_names.push(name);
            item_specific_values.push(value);
        }
    });
    const images =
        typeof details?.pictures?.PictureURL === 'string'
            ? [details?.pictures?.PictureURL]
            : details?.pictures?.PictureURL;
    const [hoveredImageIndex, setHoveredImageIndex] = useState<number>(0);
    const [selectedImageURL, setSelectedImageURL] = useState<string>('');
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
    for (let i = 0; i < headers.length; i++) {
        if (headers[i].includes('名称')) {
            if (
                props.row[headers[i]].includes('MPN') &&
                headers.find((element: string) => element === 'MPN')
            ) {
                delete props.row[headers[i]];
                headers.splice(i, 1);
                i--;
            } else {
                headers[i] = props.row[headers[i]];
                props.row[headers[i]] = props.row[headers[i + 1]];
                headers.splice(i + 1, 1);
                i--;
            }
        } else if (headers[i].includes('aa')) {
            headers.splice(i, 1);
            images.push(props.row['image_url']);
            delete props.row['image_url'];
            i--;
        }
    }
    useEffect(() => {
        setSelectedImageURL(images[hoveredImageIndex]);
    }, [hoveredImageIndex]);
    return (
        <Container>
            {headers.map((col: any, index: number) => {
                if (headers[index] !== '商品説明') {
                    return (
                        <Row
                            key={index}
                            style={{
                                borderLeft: '1px solid black',
                                borderRight: '1px solid black',
                                borderTop: '1px solid black',
                            }}
                        >
                            <Col
                                style={{
                                    borderRight: '1px solid black',
                                    maxWidth: '200px',
                                }}
                            >
                                <strong>{headers[index]}</strong>
                            </Col>
                            <Col>
                                {headers[index].includes('画像') && (
                                    <Image src={col}></Image>
                                )}
                                {!headers[index].includes('画像') && (
                                    <span>{props.row[col]}</span>
                                )}
                            </Col>
                        </Row>
                    );
                } else {
                    return <></>;
                }
            })}
            {item_specific_names.length > 0 &&
                item_specific_names.map((name, index) => (
                    <Row
                        key={index}
                        style={{
                            borderLeft: '1px solid black',
                            borderRight: '1px solid black',
                            borderTop: '1px solid black',
                            borderBottom:
                                index === item_specific_names.length - 1
                                    ? '1px solid black'
                                    : '',
                        }}
                    >
                        <Col
                            style={{
                                borderRight: '1px solid black',
                                maxWidth: '200px',
                            }}
                        >
                            <strong>{name}</strong>
                        </Col>
                        <Col>
                            <span>{item_specific_values[index]}</span>
                        </Col>
                    </Row>
                ))}
            {images.length > 0 && (
                <Row
                    style={{
                        borderLeft: '1px solid black',
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                    }}
                >
                    <Col
                        style={{
                            alignItems: 'center',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                            borderRight: '1px solid black',
                            maxWidth: '200px',
                        }}
                    >
                        {images.map((url: string, index: number) => {
                            return (
                                <Row
                                    key={index.toString()}
                                    style={{
                                        margin: '0px',
                                        width: '100%',
                                        height: '43px',
                                        justifyContent: 'center',
                                        marginTop: '8px',
                                    }}
                                >
                                    <Image
                                        onClick={() =>
                                            setSelectedImageIndex(index)
                                        }
                                        onMouseLeave={() =>
                                            setHoveredImageIndex(
                                                selectedImageIndex,
                                            )
                                        }
                                        onMouseEnter={() =>
                                            setHoveredImageIndex(index)
                                        }
                                        style={{
                                            padding: '0px',
                                            width: '43px',
                                            height: '43px',
                                            border:
                                                index === selectedImageIndex
                                                    ? '2px solid blue'
                                                    : '1px solid black',
                                        }}
                                        src={url}
                                    ></Image>
                                </Row>
                            );
                        })}
                    </Col>
                    <Col
                        id="testing"
                        style={{
                            overflow: 'auto',
                            whiteSpace: 'nowrap',
                            height: '700px',
                            minHeight: '700px',
                            maxHeight: '700px',
                        }}
                    >
                        <Image src={selectedImageURL} />
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default ItemOverlay;
