import React from 'react';
import { Alert } from 'react-bootstrap';
import { strings } from '../../locales/locales';

const SystemMessageAlert: React.FC = () => {
    return (
        <Alert variant="warning">
            <Alert.Heading>{strings.systemMessageAlertHeader}</Alert.Heading>
            {strings.systemMessageAlertMutedText}
            <hr />
            {strings.systemMessageAlertBody}
        </Alert>
    );
};

export default SystemMessageAlert;
