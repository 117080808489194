import React from 'react';
import { Placeholder, Card, Alert } from 'react-bootstrap';

interface StoreTableToolkitSkeleton {
    isLoading: boolean;
    isError: boolean;
    error: Error | null;
}

const StoreTableToolkitSkeleton: React.FC<
    React.PropsWithChildren<StoreTableToolkitSkeleton>
> = (props) => {
    if (props.isLoading) {
        return (
            <Placeholder as={Card.Body} animation="glow">
                <Placeholder xs={12} style={{ height: '400px' }} />
            </Placeholder>
        );
    }
    if (props.isError) {
        return (
            <Alert style={{ marginTop: '4px' }} variant="danger">
                <Alert.Heading>アイテムを取れません</Alert.Heading>
                <p>{props.error?.message ?? 'An unknown error occurred'}</p>
            </Alert>
        );
    }
    return <>{props.children}</>;
};

export default StoreTableToolkitSkeleton;
