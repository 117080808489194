import { useAccountContext } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import AccountStoresPage from '../pages/account/AccountStoresPage';
import StorePage from '../pages/store/StorePage';
import { AccountStoresWrapper, StoresWrapper } from './StoreRouter';



const HomeRouter: React.FC = () => {
 
  return (
    <Routes>
      <Route path='/' element={<AccountStoresWrapper />} />
      <Route path=':storeId' element={<StoresWrapper />} />
    </Routes>
  )
};

export default HomeRouter;