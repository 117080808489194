import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
    en: {
        accountBreadCrumb: 'Store Registration',
        ebayStoreId: 'Ebay Store Id',
        ebayStoreNote: 'Ebay Note',
        ebayStoreIdPlaceholder: 'Enter the store id for a seller on ebay',
        ebayStoreNotePlaceholder: 'Enter a description about this store',
        addStoreButtonText: 'Add store',
        removeStoreButtonText: 'Remove store',
        removeStoreRowButtonText: 'Remove',
        viewStoreItemsButton: 'View Sold Items',
        ebayStoreIdColumnTitle: 'Store Id',
        ebayStoreNoteColumnTitle: 'Store Note',
        soldItemsColumnTitle: 'Sold Items Total',
        ebayStoreItemAveragePriceColumnTitle: 'Average Item Price',
        ebayStoreLastUpdatedOnColumnTitle: 'Last Updated On',
        ebayStoreItemListColumnTitle: 'Item List',
        systemMessageAlertHeader: 'You are allowed to add up to 10 sellers',
        systemMessageAlertMutedText:
            'We retrieve the latest store sold items everyday at 9pm',
        systemMessageAlertBody:
            'Due to system reasons, there may be discrepancies between the number of items in the store list and the item list.',
    },
    jp: {
        accountBreadCrumb: 'ストア管理',
        ebayStoreId: '取得したいeBayセラーID',
        ebayStoreNote: 'メモ',
        ebayStoreIdPlaceholder:
            '取得したい日本人eBayセラーのセラーIDを入力して下さい',
        ebayStoreNotePlaceholder: '管理用に名前をつけておくと便利です',
        addStoreButtonText: '登録',
        removeStoreButtonText: 'セラーを削除する',
        removeStoreRowButtonText: '削除',
        viewStoreItemsButton: 'リスト表示',
        ebayStoreIdColumnTitle: 'セラーID',
        ebayStoreNoteColumnTitle: 'メモ',
        soldItemsColumnTitle: '商品数',
        ebayStoreItemAveragePriceColumnTitle: '平均単価',
        ebayStoreLastUpdatedOnColumnTitle: 'eBay時間',
        ebayStoreItemListColumnTitle: '操作',
        systemMessageAlertHeader:
            'サーバー負荷を避けるため最大10セラーまでの登録となります。',
        systemMessageAlertMutedText: '毎日午後9時ごろにデータが更新されます。',
        systemMessageAlertBody:
            'システムの都合上、ストアリストとアイテムリストにあるアイテム数に誤差が発生することがあります',
    },
});

strings.setLanguage('jp');
