import React, { useEffect, useState } from 'react';
import { Layout } from 'ui';
import { Container, Card } from 'react-bootstrap';
import { getStoreSoldItemsHelper } from '../../pages/store/StorePage.helpers';
import FiltersCard from '../../components/store/FiltersCard';
import { useQuery } from '@tanstack/react-query';
import StoreBreadCrumbs from '../../components/store/StoreBreadCrumbs';
import StoreTableToolkit from '../../components/store_table/StoreTableToolkit';
import StoreTableToolkitSkeleton from '../../components/store_table/StoreTableToolkitSkeleton';
import { Filters, GetStoreSoldItemsResponse } from 'apis';
import { clearFilters, search } from '../../components/store_table/filters';
import { Item } from 'apis/store/StoreApis.types';

interface StorePageProps {
    fakeUser: string | null;
    storeId?: string;
}

export type FilterList = {
    format: string;
    productTitle: string;
    contractPriceLow: string;
    contractPriceHigh: string;
    itemId: string;
    endTimeStart: string;
    endTimeEnd: string;
};
const StorePage: React.FC<StorePageProps> = (props: StorePageProps) => {
    if (props.storeId == undefined || props.storeId === null) {
        return <div>No store found</div>;
    }
    const [page, setPage] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);
    const [filters, setFilters] = useState<Filters>({});
    const [sort, setSort] = useState<{
        sortField: string;
        sortOrder: 'asc' | 'desc';
    }>({
        sortField: 'title',
        sortOrder: 'asc',
    });
    const { isLoading, isError, error, data } = useQuery<
        GetStoreSoldItemsResponse,
        Error
    >(
        [
            `store`,
            props.storeId,
            page,
            limit,
            JSON.stringify(filters),
            JSON.stringify(sort),
        ],
        async () => {
            const [result, error] = await getStoreSoldItemsHelper({
                storeId: props.storeId ?? '',
                domain: 'ebay',
                filters: filters,
                pagination: {
                    limit: limit,
                    page: page,
                },
                sort: sort,
            });
            if (result) {
                return result;
            }
            throw new Error(error?.data?.message);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );
    /*const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState<{
        storeItems: Item[];
        count: number;
    }>({
        storeItems: [],
        count: 0,
    });
    useEffect(() => {
        getStoreSoldItemsHelper({
            storeId: props.storeId ?? '',
            domain: 'ebay',
            filters: filters,
            pagination: {
                limit: limit,
                page: page,
            },
            sort: sort,
        }).then((response) => {
            const [result, error] = response;
            if (result) {
                setData({
                    storeItems: result.storeItems,
                    count: result.count,
                });
            }
        });
    }, []);*/
    return (
        <Layout collapsedWidth={1803} noDisplayWidth={1641}>
            <Container
                fluid
                style={{
                    height: '100vh',
                    fontFamily:
                        'Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif',
                }}
            >
                <h1 className="mt-4 mb-4" style={{ color: '#73879C' }}>
                    登録済みeBay Seller Soldリスト
                </h1>
                <Card className="mb-4">
                    <Card.Body>
                        <StoreBreadCrumbs storeId={props.storeId ?? ''} />
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Header style={{ color: '#73879C' }}>
                        フィルター
                    </Card.Header>
                    <Card.Body>
                        <FiltersCard
                            setPage={setPage}
                            setFilters={setFilters}
                            search={search}
                            clear={clearFilters}
                            isLoading={isLoading}
                        />
                    </Card.Body>
                </Card>

                <Card className="mt-4">
                    <Card.Header style={{ color: '#73879C' }}>
                        アイテムリスト
                    </Card.Header>
                    <Card.Body>
                        <StoreTableToolkitSkeleton
                            isLoading={isLoading}
                            isError={isError}
                            error={error}
                        >
                            <StoreTableToolkit
                                storeId={props.storeId}
                                data={data?.storeItems ?? []}
                                setPage={setPage}
                                setLimit={setLimit}
                                setSort={setSort}
                                isLoading={isLoading}
                                numberOfItems={data?.count ?? 0}
                                filters={filters}
                            />
                        </StoreTableToolkitSkeleton>
                    </Card.Body>
                </Card>
            </Container>
        </Layout>
    );
};

export default StorePage;
