import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import StorePage from '../pages/store/StorePage';
import AdminPage from '../pages/admin/AdminPage';

const AdminRouter: React.FC = () => {
    return (
        <Routes>
            <Route path='/' element={<AdminPage />} />
            <Route path=':storeId' element={<AdminWrapper />} />
        </Routes >
    )
};

const AdminWrapper: React.FC = () => {
    const { storeId } = useParams<{ storeId: string }>();
    return (
        <StorePage storeId={storeId} fakeUser={null}/>
      )
};

export default AdminRouter;