import React, { useEffect, useState } from 'react';
import { Container, Alert, Card, Row, Col } from 'react-bootstrap';
import { Layout } from 'ui';
import SystemMessageAlert from '../../components/account/SystemMessageAlert';
import ServerMessageAlert from '../../components/account/ServerMessageAlert';
import AccountBreadCrumbs from '../../components/account/AccountBreadCrumbs';
import AddStoreForm from '../../components/account/AddStoreForm';
import AccountTable from '../../components/account/AccountTable';
import { retrieveUserStores } from './AccountStoresPage.helpers';
import { useQuery } from '@tanstack/react-query';
import { useToastContext } from 'hooks';
import { strings } from '../../locales/locales';

const AccountStoresPage: React.FC<{ fakeUser: string | null }> = (props) => {
    /*const toasts = useToastContext();
    useEffect(() => {
        toasts.showNotification({
            header: 'Testing',
            body: 'Testing node',
        });
    }, []);*/
    const [errorMessage, setErrorMessage] = useState('');
    const [showServerError, setShowServerError] = useState<boolean>(false);
    const { data, isLoading, isError } = useQuery(
        ['user-stores', props.fakeUser],
        () => retrieveUserStores(props.fakeUser),
    );
    /*
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<
        {
            storeId: string;
            storeName: string;
            soldItemsCount: number;
            lastUpdatedOn: Date;
            averagePrice: number;
        }[]
    >([]);
    useEffect(() => {
        retrieveUserStores(props.fakeUser).then((response) => {
            setData(response);
        });
    }, []);*/
    return (
        <Layout collapsedWidth={763} noDisplayWidth={601}>
            <Container
                fluid
                style={{
                    fontFamily:
                        'Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif',
                }}
            >
                <ServerMessageAlert />
                <h1 className="mt-4 mb-4" style={{ color: '#73879C' }}>
                    {strings.accountBreadCrumb}
                </h1>
                <AccountBreadCrumbs />
                <Card className="mb-4">
                    <Card.Body>
                        <Row>
                            <SystemMessageAlert />
                            <Col />
                            <Col xs={8}>
                                <AddStoreForm
                                    stores={data ?? []}
                                    disabled={(data ?? []).length === 10}
                                />
                            </Col>
                            <Col />
                        </Row>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Row>
                            <Row>
                                {errorMessage !== '' && showServerError && (
                                    <Alert
                                        variant="danger"
                                        onClose={() => {
                                            setShowServerError(false);
                                            setErrorMessage('');
                                        }}
                                        dismissible
                                    >
                                        <Alert.Heading>
                                            Server Error
                                        </Alert.Heading>
                                        <span>{errorMessage}</span>
                                    </Alert>
                                )}
                            </Row>
                            <Row>
                                <AccountTable
                                    isLoading={isLoading}
                                    data={data ?? []}
                                    fakeUser={props.fakeUser}
                                />
                            </Row>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </Layout>
    );
};

export default AccountStoresPage;
