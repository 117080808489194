export const UpcSearchLinks = (
    upc: string,
    image: string,
): { [index: string]: string } => {
    return {
        'Google Picture': `https://lens.google.com/uploadbyurl?url=${image}`,
        Ebay: `https://www.ebay.com/sch/i.html?_from=R40&_trksid=p2380057.m570.l1313&_nkw=${upc}&_sacat=0`,
        'Yahoo Shop': `https://shopping.yahoo.co.jp/search?p=${upc}&renderBySimilarity=1`,
        'Yahoo Auction': `https://auctions.yahoo.co.jp/search/search?auccat=&tab_ex=commerce&ei=utf-8&aq=-1&oq=&sc_i=&fr=auc_top&p=${upc}&x=0&y=0`,
        Rakuten: `https://search.rakuten.co.jp/search/mall/${upc}/`,
        'Amazon Japan': `https://www.amazon.co.jp/s?k=${upc}&__mk_ja_JP=%E3%82%AB%E3%82%BF%E3%82%AB%E3%83%8A&ref=nb_sb_noss_2`,
        'Amazon US': `https://www.amazon.com/s?k=${upc}&ref=nb_sb_noss`,
        Google: `https://www.google.com/search?q=${upc}`,
    };
};
