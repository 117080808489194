import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
    AccountProvider,
    LMEModalProvider,
    Logout,
    ToastProvider,
} from 'hooks';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import NotFoundPage from './pages/NotFoundPage';
import AdminRouter from './routers/AdminRouter';
import HomeRouter from './routers/HomeRouter';

const queryClient = new QueryClient();
function App(): React.ReactElement {
    return (
        <Router>
            <QueryClientProvider client={queryClient}>
                <ToastProvider autoClose={10} zIndex={99} limit={10}>
                    <AccountProvider>
                        <LMEModalProvider>
                            <Routes>
                                <Route
                                    path="/"
                                    element={<Navigate to="/home" replace />}
                                />
                                <Route path="home/*" element={<HomeRouter />} />
                                <Route
                                    path="admin/*"
                                    element={<AdminRouter />}
                                />
                                <Route path="*" element={<NotFoundPage />} />
                                <Route path="/logout" element={<Logout />} />
                            </Routes>
                        </LMEModalProvider>
                    </AccountProvider>
                </ToastProvider>
            </QueryClientProvider>
        </Router>
    );
}

export default App;
