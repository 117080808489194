import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    addStoreToAccount,
    AddStoreToAccountResponse,
    getEbayStoreAboutPage,
} from 'apis';
import { Alert, Button, Form, Row, Spinner } from 'react-bootstrap';
import { AiOutlineCheck } from 'react-icons/ai';
import { TiCancel } from 'react-icons/ti';
import { CloseableAlert } from 'ui';
import { strings } from '../../locales/locales';

interface Store {
    storeId: string;
    storeName: string;
    soldItemsCount: number;
    lastUpdatedOn: Date;
    averagePrice: number;
}

interface AddStoreFormProps {
    disabled: boolean; // Set to max length
    stores: Store[];
}

const AddStoreForm: React.FC<AddStoreFormProps> = (props) => {
    const queryClient = useQueryClient();
    const [selectedStoreId, setSelectedStoreId] = useState<string>('');
    const [selectedStoreName, setSelectedStoreName] = useState<string>('');
    const [isNew, setIsNew] = useState<boolean>(true);
    const { isError, error, isLoading, isSuccess, mutate } = useMutation<
        AddStoreToAccountResponse,
        Error
    >(
        async () => {
            if (
                props.stores.filter(
                    (store) => store.storeId === selectedStoreId,
                ).length > 0
            ) {
                throw new Error('Cannot add the same store twice');
            }
            /*const [isStoreJapanResult, isStoreJapanError] =
                await getEbayStoreAboutPage({
                    storeName: selectedStoreId,
                });
            if (
                isStoreJapanError ||
                isStoreJapanResult?.location.toUpperCase() !== 'JAPAN'
            ) {
                throw new Error(
                    `${selectedStoreId} is not located in Japan!  You can verify the store's location at https://www.ebay.com/str/${selectedStoreId}#tab1.  If the page returns 'Sorry, this store was not found.' then you entered a SELLER ID, not a STORE ID.  You can find the seller's STORE ID by opening an item for the seller in ebay, and clicking on their SELLER ID to be redirected to their store.`,
                );
            }*/

            const [result, error] = await addStoreToAccount({
                storeId: selectedStoreId.trim(),
                storeName: selectedStoreName.trim(),
            });

            if (result) {
                return result;
            }
            throw new Error(error?.data?.message);
        },
        {
            onSuccess: () => {
                setSelectedStoreId('');
                setSelectedStoreName('');
                setIsNew(false);
                queryClient.invalidateQueries(['user-stores']);
            },
            onError: () => {
                setIsNew(false);
            },
        },
    );
    useEffect(() => {
        if ((!isNew && selectedStoreId !== '') || selectedStoreName !== '')
            setIsNew(true);
    }, [selectedStoreId, selectedStoreName]);
    return (
        <Row>
            {isError && (
                <CloseableAlert
                    variant="danger"
                    header={'Unable to add new store'}
                    body={error.message}
                    close={isNew}
                />
            )}
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>{strings.ebayStoreId}</Form.Label>
                    <Form.Control
                        readOnly={isLoading || props.disabled}
                        onChange={(e) => setSelectedStoreId(e.target.value)}
                        value={selectedStoreId}
                        placeholder={strings.ebayStoreIdPlaceholder}
                    />
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Label>{strings.ebayStoreNote}</Form.Label>
                    <Form.Control
                        readOnly={isLoading || props.disabled}
                        onChange={(e) => setSelectedStoreName(e.target.value)}
                        value={selectedStoreName}
                        placeholder={strings.ebayStoreNotePlaceholder}
                    />
                </Form.Group>
                <Form.Group style={{ marginTop: '4px' }}>
                    <Button
                        disabled={
                            props.disabled ||
                            isLoading ||
                            selectedStoreName === '' ||
                            selectedStoreId === ''
                        }
                        onClick={() => mutate()}
                        style={{
                            backgroundColor:
                                isSuccess && !isNew
                                    ? 'rgba(49,207,128,255)'
                                    : isError && !isNew
                                    ? 'rgb(236, 98, 84, 255)'
                                    : 'rgba(79,164,223,255)',
                            borderColor: 'rgba(79,164,223,255)',
                        }}
                    >
                        {!isLoading &&
                            (isError && !isNew ? (
                                <TiCancel />
                            ) : (
                                <AiOutlineCheck />
                            ))}
                        {isLoading && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        {strings.addStoreButtonText}
                    </Button>
                    {props.disabled && (
                        <Form.Text
                            style={{
                                WebkitTextFillColor: 'red',
                            }}
                        >
                            {' '}
                            {strings.removeStoreButtonText}
                        </Form.Text>
                    )}
                </Form.Group>
            </Form>
        </Row>
    );
};

export default AddStoreForm;
