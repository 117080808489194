import Api from '../common/Api';
import { DefaultResponse } from '../common/Api.types';
import * as Types from './UsersApis.types';

const apis = new Api('/api/auth');

export const login = async (
    request: Types.LoginRequest,
): Promise<DefaultResponse<Types.GetUserResponse>> => {
    await apis.get<Types.LoginRequest, Types.LoginResponse>(
        'getTokenFromCode',
        request,
    );
    const response = await apis.get<
        Types.GetUserRequest,
        Types.GetUserResponse
    >('getUser');
    return response;
};

export const isLoggedIn = async (): Promise<
    DefaultResponse<Types.IsLoggedInResponse>
> => {
    return await apis.get<Types.IsLoggedInRequest, Types.IsLoggedInResponse>(
        'isLoggedIn',
    );
};

export const logout = async (): Promise<
    DefaultResponse<Types.LogoutResponse>
> => {
    return await apis.delete<Types.LogoutRequest, Types.LogoutResponse>(
        'logout',
    );
};

export const isAdmin = async (): Promise<
    DefaultResponse<Types.IsAdminResponse>
> => {
    return await apis.get<Types.IsAdminRequest, Types.IsAdminResponse>(
        'isAdmin',
    );
};
