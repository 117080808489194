import React from 'react';
import { Spinner } from 'react-bootstrap';
import BootstrapTable, {
    BootstrapTableProps,
} from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
interface StoreTableProps {
    paginationTableProps: BootstrapTableProps;
    baseProps: any;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setLimit: React.Dispatch<React.SetStateAction<number>>;
    setSort: React.Dispatch<
        React.SetStateAction<{ sortField: string; sortOrder: 'asc' | 'desc' }>
    >;
    isLoading: boolean;
    setSelectedItems: React.Dispatch<React.SetStateAction<Set<string>>>;
    selectedItems: Set<string>;
}

const StoreTable: React.FC<StoreTableProps> = (props) => {
    return (
        <BootstrapTable
            classes="ScrollTable"
            {...props.paginationTableProps}
            {...props.baseProps}
            bootstrap4
            onTableChange={(type, newState) => {
                if (type === 'pagination') {
                    props.setPage(newState.page);
                    props.setLimit(newState.sizePerPage);
                }
                if (type === 'sort') {
                    props.setSort({
                        sortField: newState.sortField,
                        sortOrder: newState.sortOrder,
                    });
                }
            }}
            loading={props.isLoading}
            remote={{
                filter: false,
                pagination: true,
                sort: true,
                cellEdit: false,
            }}
            selectRow={{
                mode: 'checkbox',
                onSelectAll: (
                    isSelect: boolean,
                    rows: { item_id: string }[],
                ) => {
                    if (isSelect) {
                        rows.forEach((row) =>
                            props.selectedItems.add(row.item_id),
                        );
                    } else {
                        rows.forEach((row) => {
                            if (props.selectedItems.has(row.item_id)) {
                                props.selectedItems.delete(row.item_id);
                            }
                        });
                    }
                },
                onSelect: (
                    row: { item_id: string },
                    isSelect: boolean,
                    _rowIndex: number,
                ) => {
                    if (isSelect) {
                        props.selectedItems.add(row.item_id);
                    } else if (props.selectedItems.has(row.item_id)) {
                        props.selectedItems.delete(row.item_id);
                    }
                },
            }}
            noDataIndication={() => {
                return props.isLoading ? (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : (
                    <span>アイテムがありません</span>
                );
            }}
        />
    );
};

export default StoreTable;
