import React from 'react';
import { Card, Breadcrumb } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { strings } from '../../locales/locales';
const AccountBreadCrumbs: React.FC = () => {
    return (
        <Card className="mb-4">
            <Card.Body>
                <Breadcrumb>
                    <Breadcrumb.Item
                        as={NavLink}
                        active
                        to="/"
                        style={{ color: '#73879C' }}
                    >
                        {strings.accountBreadCrumb}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Card.Body>
        </Card>
    );
};

export default AccountBreadCrumbs;
