import Api from '../common/Api';
import { DefaultResponse } from '../common/Api.types';
import * as Types from './SystemApis.types';

const apis = new Api('/api/system');

export const addSystemMessage = async (
    request: Types.AddSystemMessageRequest,
): Promise<DefaultResponse<Types.AddSystemMessageResponse>> => {
    return await apis.post<
        Types.AddSystemMessageRequest,
        Types.AddSystemMessageResponse
    >('addSystemMessage', request);
};

export const removeSystemMessage = async (): Promise<
    DefaultResponse<Types.RemoveSystemMessageResponse>
> => {
    return await apis.delete<
        Types.RemoveSystemMessageRequest,
        Types.RemoveSystemMessageResponse
    >('removeSystemMessage');
};

export const getSystemMessage = async (): Promise<
    DefaultResponse<Types.GetSystemMessageResponse>
> => {
    return await apis.get<
        Types.GetSystemMessageRequest,
        Types.GetSystemMessageResponse
    >('getSystemMessage');
};

export const getAllUsers = async (): Promise<
    DefaultResponse<Types.GetAllUsersResponse>
> => {
    return await apis.get<Types.GetAllUsersRequest, Types.GetAllUsersResponse>(
        'getAllUsers',
    );
};

export const getAllStores = async (): Promise<
    DefaultResponse<Types.GetAllStoresResponse>
> => {
    return await apis.get<
        Types.GetAllStoresRequest,
        Types.GetAllStoresResponse
    >('getAllStores');
};
