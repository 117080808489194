import React from 'react';
import { Row, Col, Button, Spinner, Nav } from 'react-bootstrap';
import { VscEdit } from 'react-icons/vsc';
import { NavLink } from 'react-router-dom';
import cellEditFactory from 'react-bootstrap-table2-editor';
import BootstrapTable from 'react-bootstrap-table-next';
import { updateStoreMemo, UpdateStoreMemoResponse } from 'apis';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    removeStore,
    retrieveUserStores,
} from '../../pages/account/AccountStoresPage.helpers';
import { headers } from './AccountTable.consts';

interface Store {
    storeId: string;
    storeName: string;
    soldItemsCount: number;
    lastUpdatedOn: Date;
    averagePrice: number;
}

interface AccountTableProps {
    fakeUser: string | null;
    data: Store[];
    isLoading: boolean;
}

const AccountTable: React.FC<AccountTableProps> = (props) => {
    const queryClient = useQueryClient();

    const editCellPost = useMutation<
        UpdateStoreMemoResponse,
        Error,
        { storeId: string; storeName: string }
    >(
        async (store: { storeId: string; storeName: string }) => {
            const [result, error] = await updateStoreMemo({
                storeId: store.storeId,
                storeName: store.storeName,
            });
            if (result) {
                return result;
            }
            throw new Error(error?.data?.message);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['user-stores']);
            },
        },
    );
    const removeStorePost = useMutation(
        async (storeId: string) => {
            const [result, error] = await removeStore(storeId);
            if (result) {
                return result;
            }
            throw new Error(error);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['user-stores']);
            },
        },
    );
    const cellEdit = cellEditFactory({
        mode: 'click',
        afterSaveCell: (
            oldVal: string,
            newVal: string,
            item: {
                storeId: string;
                storeName: string;
                soldItemsCount: number;
                lastUpdatedOn: Date;
                averagePrice: number;
            },
            column: any,
        ) =>
            editCellPost.mutate({
                storeId: item.storeId,
                storeName: newVal,
            }),
    });
    return (
        <BootstrapTable
            keyField="id"
            cellEdit={cellEdit}
            noDataIndication={() => {
                if (props.isLoading) {
                    return (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    );
                } else {
                    return <span>ストアを追加してリストを作成しましょう</span>;
                }
            }}
            data={props.data}
            columns={headers(props.fakeUser, removeStorePost.mutate)}
        />
    );
};

export default AccountTable;
