import React from 'react';
import { Image, OverlayTrigger } from 'react-bootstrap';

interface ImageOverlayProps {
    srcUrl: string;
}

const ImageOverlay: React.FC<ImageOverlayProps> = (props) => {
    return (
        <OverlayTrigger
            placement="right"
            overlay={<Image style={{ zIndex: '1' }} src={props.srcUrl} />}
        >
            <div
                style={{
                    justifyContent: 'center',
                    width: '100px',
                    height: '100px',
                }}
            >
                <Image
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    src={props.srcUrl}
                />
            </div>
        </OverlayTrigger>
    );
};

export default ImageOverlay;
