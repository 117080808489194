import Api from '../common/Api';
import { DefaultResponse } from '../common/Api.types';
import * as Types from './EbayApis.types';

const apis = new Api('/api/ebay');

export const getEbayStoreAboutPage = async (
    request: Types.GetEbayStoreAboutPageRequest,
): Promise<DefaultResponse<Types.GetEbayStoreAboutPageResponse>> => {
    return await apis.get<
        Types.GetEbayStoreAboutPageRequest,
        Types.GetEbayStoreAboutPageResponse
    >('getEbayStoreAboutPage', request);
};

export const exchangeAccessCodeForToken = async (
    request: Types.ExchangeAccessCodeForTokenRequest,
): Promise<DefaultResponse<Types.ExchangeAccessCodeForTokenResponse>> => {
    return await apis.post<
        Types.ExchangeAccessCodeForTokenRequest,
        Types.ExchangeAccessCodeForTokenResponse
    >('exchangeAccessCodeForToken', request);
};

export const generateLoginURL = async (): Promise<
    DefaultResponse<Types.GenerateLoginURLResponse>
> => {
    return await apis.post<
        Types.GenerateLoginURLRequest,
        Types.GenerateLoginURLResponse
    >('generateLoginURL');
};

export const isEbayConnected = async (): Promise<
    DefaultResponse<Types.IsEbayConnectedResponse>
> => {
    return await apis.post<
        Types.IsEbayConnectedRequest,
        Types.IsEbayConnectedResponse
    >('isEbayConnected');
};

export const findItemsAdvanced = async (
    request: Types.FindItemsAdvancedRequest,
): Promise<
    DefaultResponse<{ items: Types.ReturnItem[]; totalPages: number }>
> => {
    const [result, error] = await apis.post<
        Types.FindItemsAdvancedRequest,
        Types.FindItemsAdvancedResponse
    >('findItemsAdvanced', request);
    if (result) {
        const items: any[] = [];
        if (result.findItemsAdvancedResponse?.searchResult?.item) {
            result.findItemsAdvancedResponse.searchResult.item.forEach(
                (item: any) => items.push(item),
            );
        }
        return [
            {
                items: items.map((item: any) => {
                    return {
                        item_id: item.itemId._text,
                        title: item.title._text,
                        price: item.sellingStatus.currentPrice._text,
                        format: item.listingInfo.listingType._text,
                        bids: item.sellingStatus.bidCount?._text || '-',
                        end_date: item.listingInfo.endTime?._text || '-',
                        quantity: '0',
                        watch_count: item.listingInfo.watchCount?._text || '0',
                        views: '0',
                    };
                }),
                totalPages: parseInt(
                    result.findItemsAdvancedResponse.paginationOutput.totalPages
                        ._text,
                ),
            },
            null,
        ];
    } else {
        return [result, error];
    }
};

export const getItems = async (
    requests: Types.GetItemRequest[],
): Promise<DefaultResponse<Types.GetItemResponse[]>> => {
    const responses = await Promise.all(
        requests.map((request) => getItem(request)),
    );
    const items: Types.GetItemResponse[] = [];
    responses
        .filter((response) => response[0] !== null)
        .filter(
            (response: DefaultResponse<Types.GetItemResponse>) =>
                response?.[0]?.GetItemResponse?.Ack._text === 'Success',
        )
        .forEach((response: DefaultResponse<Types.GetItemResponse>) => {
            const [result, error] = response;
            if (result) items.push(result);
        });
    return [items, null];
};

export const getItem = async (
    request: Types.GetItemRequest,
): Promise<DefaultResponse<Types.GetItemResponse>> => {
    return await apis.post<Types.GetItemRequest, Types.GetItemResponse>(
        'getItem',
        { itemId: request.itemId },
    );
};

export const getActiveListing = async (): Promise<
    DefaultResponse<
        {
            item_id: string;
            title: string;
            price: string;
            format: string;
            bids: string;
            end_date: string;
            quantity: string;
            image_url: string;
            watchers: string;
            views: string;
        }[]
    >
> => {
    const [result, error] = await apis.post<
        Types.GetActiveListingRequest,
        Types.GetActiveListingResponse
    >('getActiveListing', { pageNumber: 1 });
    if (result) {
        if (result.GetMyeBaySellingResponse?.Ack?._text === 'Success') {
            const calls: any[] = [];
            for (
                let i = 2;
                i <=
                parseInt(
                    result.GetMyeBaySellingResponse.ActiveList.PaginationResult
                        .TotalNumberOfPages._text,
                );
                i++
            ) {
                calls.push(
                    apis.post<
                        Types.GetActiveListingRequest,
                        Types.GetActiveListingResponse
                    >('getActiveListing', { pageNumber: i }),
                );
            }
            const responses = await Promise.all(calls);
            responses.push([result, error]);
            const results = responses
                .filter((response) => response[0] !== null)
                .filter(
                    (
                        response: DefaultResponse<Types.GetActiveListingResponse>,
                    ) =>
                        response?.[0]?.GetMyeBaySellingResponse?.Ack?._text ===
                        'Success',
                )
                .map(
                    (
                        response: DefaultResponse<Types.GetActiveListingResponse>,
                    ) =>
                        response?.[0]?.GetMyeBaySellingResponse.ActiveList
                            .ItemArray.Item,
                );
            return [
                results
                    .flat()
                    .filter((item) => item !== undefined)
                    .map((item) => {
                        return {
                            item_id: item?.ItemID._text || 'item_id',
                            title: item?.Title._text || 'title',
                            price:
                                item?.SellingStatus?.CurrentPrice?._text ||
                                'price',
                            format: item?.ListingType._text || 'format',
                            bids:
                                item?.ListingType._text === 'FixedPriceItem'
                                    ? '-'
                                    : item?.SellingStatus?.BidCount?._text,
                            end_date:
                                item?.ListingType._text === 'FixedPriceItem'
                                    ? '-'
                                    : item?.ListingDetails?.EndTime?._text,
                            quantity: item?.Quantity?._text || 'quantity',
                            image_url: '',
                            watchers: '-1',
                            views: '-1',
                        };
                    }),
                null,
            ];
        } else {
            return [
                null,
                { data: { message: 'Item not defined', statusCode: 403 } },
            ];
        }
    } else {
        return [result, error];
    }
};
