import React, { createContext, useContext, useState } from 'react';
import { ToastContainer, Toast } from 'react-bootstrap';

export type Notification = {
    header: React.ReactNode;
    body: React.ReactNode;
    delay?: number;
};

type ToastContextProps = {
    showNotification: (notification: Notification) => void;
    updateNotification: () => void;
    hideNotification: (index: number) => void;
    cleanNotifications: () => void;
    cleanNotificationsQueue: () => void;
};

interface ToastProviderProps {
    autoClose: number | false;
    limit: number;
    zIndex: number;
}

export const ToastContext = createContext<ToastContextProps>({
    showNotification: () => {
        throw Error('Not Implemented');
    },
    updateNotification: () => {
        throw Error('Not Implemented');
    },
    hideNotification: () => {
        throw Error('Not Implemented');
    },
    cleanNotifications: () => {
        throw Error('Not Implemented');
    },
    cleanNotificationsQueue: () => {
        throw Error('Not Implemented');
    },
});

export const ToastProvider: React.FC<
    React.PropsWithChildren<ToastProviderProps>
> = (props) => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const showNotification = (notification: Notification) => {
        const temp = [...notifications];
        temp.push(notification);
        setNotifications(temp);
    };
    const updateNotification = () => {
        console.log('update');
    };
    const hideNotification = (index: number) => {
        const temp = [...notifications];
        temp.splice(index, 1);
        setNotifications(temp);
    };
    const cleanNotifications = () => {
        console.log('clean');
    };
    const cleanNotificationsQueue = () => {
        console.log('clean');
    };
    return (
        <ToastContext.Provider
            value={{
                showNotification: showNotification,
                updateNotification: updateNotification,
                hideNotification: hideNotification,
                cleanNotifications: cleanNotifications,
                cleanNotificationsQueue: cleanNotificationsQueue,
            }}
        >
            <ToastContainer
                position="bottom-end"
                style={{
                    opacity: 1,
                    zIndex: props.zIndex,
                }}
            >
                {notifications.map((notification, index) => (
                    <Toast
                        key={index.toString()}
                        id={index.toString()}
                        onClose={() => hideNotification(index)}
                        delay={notification.delay ?? 3000}
                        autohide
                        animation
                    >
                        <Toast.Header>
                            <strong>{notification.header}</strong>
                        </Toast.Header>
                        <Toast.Body>{notification.body}</Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
            {props.children}
        </ToastContext.Provider>
    );
};

export function useToastContext() {
    return useContext(ToastContext);
}
