import React from 'react';
import { Row, Col, Image, Nav } from 'react-bootstrap';
import { UpcSearchLinks } from '../../pages/store/StorePage.constants';

interface UpcIconsProps {
    upc: string;
    image_url: string;
    title: string;
}

const LinkToIcon = (id: string) => {
    switch (id) {
        case 'Ebay':
            return (
                <Image
                    src="/ebay.png"
                    style={{ width: '32px', height: '32px' }}
                />
            );
        case 'Yahoo Shop':
            return (
                <Image
                    src="/yahoo_store.png"
                    style={{ width: '32px', height: '32px' }}
                />
            );
        case 'Yahoo Auction':
            return (
                <Image
                    src="/yafuoku.png"
                    style={{ width: '32px', height: '32px' }}
                />
            );
        case 'Rakuten':
            return (
                <Image
                    src="/rakuten.png"
                    style={{ width: '32px', height: '32px' }}
                />
            );
        case 'Amazon Japan':
            return (
                <Image
                    src="/amazon.co.jp.png"
                    style={{ width: '32px', height: '32px' }}
                />
            );
        case 'Amazon US':
            return (
                <Image
                    src="/amazon.com.png"
                    style={{ width: '32px', height: '32px' }}
                />
            );
        case 'Google Picture':
            return (
                <Image
                    src="/google_photo.png"
                    style={{ width: '32px', height: '32px' }}
                />
            );
        case 'Google':
            return (
                <Image
                    src="/google.png"
                    style={{ width: '32px', height: '32px' }}
                />
            );
        default:
            return (
                <Image
                    src="/ebay.png"
                    style={{ width: '32px', height: '32px' }}
                />
            );
    }
};

const UpcIcon: React.FC<{ upc: string; image: string }> = ({ upc, image }) => {
    const links = UpcSearchLinks(upc, image);
    return (
        <Row>
            {Object.keys(links).map((key: string, index: number) => {
                return (
                    <Col
                        style={{ width: '60px', height: '32px' }}
                        xs={2}
                        key={index.toString()}
                    >
                        <Nav.Link
                            style={{ width: '32px', height: '32px' }}
                            target="_blank"
                            rel="noreferrer"
                            href={links[key]}
                        >
                            {LinkToIcon(key)}
                        </Nav.Link>
                    </Col>
                );
            })}
        </Row>
    );
};

const UpcIcons: React.FC<UpcIconsProps> = (props) => {
    return (
        <Row>
            <Row
                style={{
                    marginLeft: '4px',
                }}
                className="mb-4"
            >
                {props.title}
            </Row>
            <Row>
                <UpcIcon upc={props.upc} image={props.image_url} />
            </Row>
        </Row>
    );
};

export default UpcIcons;
