import React, { createContext, useContext, useEffect, useState } from 'react';
import * as apis from 'apis';
import { Navigate, useLocation } from 'react-router-dom';
import { Alert, Spinner } from 'react-bootstrap';

type AccountContextType = {
    isLoggedIn: boolean;
    isAdmin: boolean;
    user: { name: string } | undefined;
    login: (data: apis.LoginRequest) => Promise<void>;
    logout: () => Promise<void>;
    isUserLoggedIn: () => Promise<boolean>;
};

export const AccountContext = createContext<AccountContextType>({
    isLoggedIn: false,
    isAdmin: false,
    user: undefined,
    login: async () => {
        throw new Error('Not implemented');
    },
    logout: async () => {
        throw new Error('Not implemented');
    },
    isUserLoggedIn: async () => {
        throw new Error('Not implemented');
    },
});

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export const Login: React.FC = () => {
    window.location.href =
        'https://ums.saatscommerce.jp/oauth/authorize?response_type=code&client_id=NjE4YThkNzQ5NDQxMDVh';
    return <Navigate to="/home" replace />;
};
export const Logout: React.FC = () => {
    const { logout } = useAccountContext();
    logout();
    window.location.href = 'https://ums.saatscommerce.jp/users/logout';
    return <Navigate to="/home" replace />;
};
export const AccountProvider: React.FC<{ children: React.ReactNode }> = (
    props,
) => {
    const query = useQuery();
    const code = query.get('code');
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [user, setUser] = useState<{ name: string } | undefined>(undefined);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loggingOut, setLoggingOut] = useState<boolean>(false);
    const login = async (data: apis.LoginRequest) => {
        const [user] = await apis.login(data);
        if (user) {
            setIsLoggedIn(true);
            setUser({ name: user.user });
        } else {
            setIsLoggedIn(false);
            setUser(undefined);
        }
    };
    const logout = async () => {
        const [user, error] = await apis.logout();
        setUser(undefined);
        setLoggingOut(true);
        if (user) {
            console.log(user);
        } else {
            console.error(error);
        }
    };
    const isUserLoggedIn = async () => {
        const [user] = await apis.isLoggedIn();
        if (user && user.user) {
            setIsLoggedIn(true);
            setUser({
                name: user.user || '',
            });
            return true;
        } else {
            setIsLoggedIn(false);
            setUser(undefined);
            return false;
        }
    };
    useEffect(() => {
        setLoading(true);
        isUserLoggedIn().then((isLoggedIn) => {
            if (!isLoggedIn) {
                if (code == null) {
                    setLoading(false);
                } else {
                    login({ code: code }).then(() => setLoading(false));
                }
            } else {
                setLoading(false);
            }
        });
    }, []);
    useEffect(() => {
        if (user && user.name !== '') {
            apis.isAdmin().then(
                (response: apis.DefaultResponse<apis.IsAdminResponse>) => {
                    const [isAdminResult] = response;
                    if (isAdminResult) {
                        setIsAdmin(isAdminResult.isAdmin);
                    } else {
                        setIsAdmin(false);
                    }
                },
            );
        }
    }, [user]);
    return (
        <AccountContext.Provider
            value={{
                isLoggedIn: isLoggedIn,
                isAdmin: isAdmin,
                user: user,
                login: login,
                logout: logout,
                isUserLoggedIn: isUserLoggedIn,
            }}
        >
            {!loggingOut && loading && (
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )}
            {!loggingOut && !loading && isLoggedIn && props.children}
            {!loggingOut && !loading && code != null && !isLoggedIn && (
                <Alert variant="variant">
                    <Alert.Heading>Unable to log in.</Alert.Heading>
                    <a href="https://sold-research.saatscommerce.jp/home">
                        Retry
                    </a>
                </Alert>
            )}
            {!loggingOut && !loading && code == null && !isLoggedIn && (
                <Login />
            )}
            {loggingOut && <Logout />}
        </AccountContext.Provider>
    );
};

export function useAccountContext() {
    return useContext(AccountContext);
}
