import React from 'react';
import { useModalContext } from 'hooks';
import { Row, Nav, Button } from 'react-bootstrap';
import ItemOverlay from '../../pages/store/ItemOverlay';
interface ItemOverlayButtonProps {
    row: any;
    cell: any;
}

const ItemOverlayButton: React.FC<ItemOverlayButtonProps> = (props) => {
    const modalContext = useModalContext();
    return (
        <Row style={{ justifyContent: 'center' }}>
            <Row>
                <Nav.Link
                    href={`https://www.ebay.com/itm/${props.cell}`}
                    rel="noreferrer"
                    target="_blank"
                >
                    {props.cell}
                </Nav.Link>
            </Row>
            <Row>
                <Button
                    onClick={() => {
                        modalContext.hideModal();
                        modalContext.showModal({
                            title: 'Item Information',
                            open: true,
                            centered: true,
                            size: 'lg',
                            scrollable: true,
                            children: <ItemOverlay {...props} />,
                        });
                    }}
                    variant="link"
                >
                    詳細を見る
                </Button>
            </Row>
        </Row>
    );
};

export default ItemOverlayButton;
