import { Nav, Row } from 'react-bootstrap';
import EndDate from './EndDate';
import GoogleImageSearch from './GoogleImageSearch';
import ImageOverlay from './ImageOverlay';
import ItemOverlayButton from './ItemOverlayButton';
import UpcIcons from './UpcIcons';

export const headers = [
    {
        headerStyle: {
            width: '160px',
            color: '#73879C',
        },
        dataField: 'item_id',
        text: 'アイテムID',
        formatter: (cell: any, row: any) => {
            return <ItemOverlayButton row={row} cell={cell} />;
        },
    },
    {
        headerStyle: {
            width: '120px',
            color: '#73879C',
        },
        dataField: 'image_url',
        text: '商品画像',
        formatter: (cell: string) => <ImageOverlay srcUrl={cell} />,
    },
    {
        headerStyle: {
            width: 'auto',
            minWidth: '780px',
            color: '#73879C',
        },
        dataField: 'title',
        text: '商品タイトル',
        formatter: (cell: string, row: any) => {
            if (
                !row.upc ||
                row.upc === '' ||
                row.upc.toUpperCase() === 'Does not apply'.toUpperCase() ||
                row.upc.toUpperCase() === 'N/A'.toUpperCase()
            ) {
                return (
                    <Row>
                        <Row>
                            <GoogleImageSearch
                                url={cell}
                                image_url={row.image_url}
                            />
                        </Row>
                    </Row>
                );
            }
            return (
                <UpcIcons
                    title={row.title}
                    upc={row.upc}
                    image_url={row.image_url}
                />
            );
        },
    },
    {
        headerStyle: {
            width: '120px',
            color: '#73879C',
        },
        dataField: 'price',
        text: '落札価格',
        sort: true,
        formatter: (cell: any) => `$${cell}`,
    },
    {
        headerStyle: {
            width: '180px',
            color: '#73879C',
        },
        dataField: 'listing_details',
        text: '終了時間',
        sort: true,
        formatter: (cell: any) => <EndDate endTime={cell?.EndTime} />,
    },
    {
        headerStyle: {
            width: '160px',
            color: '#73879C',
        },
        dataField: 'upc',
        text: 'UPC',
        formatter: (cell: any) => {
            if (
                !cell ||
                cell === '' ||
                cell.toUpperCase() === 'Does not apply'.toUpperCase() ||
                cell.toUpperCase() === 'N/A'.toUpperCase()
            ) {
                return <span />;
            }
            return <span>{cell}</span>;
        },
    },
    {
        headerStyle: {
            width: '160px',
            color: '#73879C',
        },
        dataField: 'asin',
        text: 'ASIN',
        formatter: (cell: any) => {
            if (
                !cell ||
                cell === '' ||
                cell.toUpperCase() === 'Does not apply'.toUpperCase() ||
                cell.toUpperCase() === 'N/A'.toUpperCase()
            )
                return <span />;
            return (
                <Nav.Link
                    target="_blank"
                    rel="noreferrer"
                    href={`https://www.amazon.co.jp/dp/${cell}`}
                >
                    {cell}
                </Nav.Link>
            );
        },
    },
];
