import React, { useState } from 'react';
import { Tag, WithContext as ReactTags } from 'react-tag-input';
import './Tags.css';
const KeyCodes = {
    comma: 188,
    enter: [10, 13],
  };
  
const delimiters = [...KeyCodes.enter, KeyCodes.comma];

const Tags: React.FC<{placeholder: string, mytags: string[], addTags: React.Dispatch<React.SetStateAction<string[]>>}> = ({placeholder, mytags, addTags}) => {
    const handleDelete = (i: number) => {
        const temp = mytags.filter((tag: string, index: number) => index !== i);
        addTags(temp);
    }
    const handleAddition = (tag: Tag) => {
        const temp = [...mytags, tag.text];
        addTags(temp);
    }
    const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
        const temp = [...mytags];
        const newTags = mytags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag.text);
        addTags(newTags);
    }
    return (
        <div >
            <ReactTags
                tags={mytags.map(v => ({id: v, text: v}))}
                suggestions={[]}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                delimiters={delimiters}
                inputFieldPosition="top"
                placeholder={placeholder}
            />
        </div>
    )
}

export default Tags;