import React from 'react';
import { Row, Col, Image, Nav } from 'react-bootstrap';

interface GoogleImageSearchProps {
    url: string;
    image_url: string;
}

const GoogleImageSearch: React.FC<GoogleImageSearchProps> = (props) => {
    return (
        <Row>
            <Row
                style={{
                    marginLeft: '4px',
                }}
                className="mb-4"
            >
                {props.url}
            </Row>
            <Row>
                <Row>
                    <Col
                        style={{
                            width: '60px',
                            height: '32px',
                        }}
                        xs={2}
                    >
                        <Nav.Link
                            style={{
                                width: '32px',
                                height: '32px',
                            }}
                            target="_blank"
                            rel="noreferrer"
                            href={`https://lens.google.com/uploadbyurl?url=${props.image_url}`}
                        >
                            <Image
                                src="/google_photo.png"
                                style={{
                                    width: '32px',
                                    height: '32px',
                                }}
                            />
                        </Nav.Link>
                    </Col>
                </Row>
            </Row>
        </Row>
    );
};

export default GoogleImageSearch;
