import React, { useEffect, useState } from 'react';
import { Alert, AlertProps } from 'react-bootstrap';
interface CloseableAlertProps extends AlertProps {
    header: React.ReactNode;
    body: React.ReactNode;
    close: boolean;
}

export const CloseableAlert: React.FC<CloseableAlertProps> = (props) => {
    const [open, setOpen] = useState<boolean>(true);
    useEffect(() => {
        setOpen(!props.close);
    }, [props.close]);
    if (!open) return <></>;
    return (
        <Alert
            variant={props.variant}
            onClose={() => setOpen(false)}
            dismissible
        >
            <Alert.Heading>{props.header}</Alert.Heading>
            {props.body}
        </Alert>
    );
};
