import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Image } from 'react-bootstrap';
import { FaQuestionCircle, FaExchangeAlt, FaCog } from 'react-icons/fa';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { AccountContext } from 'hooks';
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarContent,
} from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';

const initItems = [
    {
        title: 'アプリの切り替え',
        icon: <FaExchangeAlt style={{ color: 'rgba(229,229,229,255)' }} />,
        links: [
            {
                title: 'E-LISTER',
                externalLink: 'https://e-lister.saatscommerce.jp/',
                newTab: false,
                icon: (
                    <Image
                        src="/saats_e_lister.png"
                        style={{ marginRight: '0px' }}
                    />
                ),
            },
            {
                title: 'O-MANAGER',
                externalLink: 'https://order-manager.saatscommerce.jp/',
                newTab: false,
                icon: (
                    <Image
                        src="/saats_o_manager.png"
                        style={{ marginRight: '0px' }}
                    />
                ),
            },
            {
                title: 'Y-LISTER',
                externalLink: 'https://y-lister.saatscommerce.jp/',
                newTab: false,
                icon: (
                    <Image src="/y_lister.ico" style={{ marginRight: '0px' }} />
                ),
            },
        ],
    },
    {
        title: '管理',
        icon: <FaCog style={{ color: 'rgba(229,229,229,255)' }} />,
        links: [
            {
                title: 'ストア管理',
                link: '/home',
                icon: <span />,
            },
        ],
    },
    {
        title: 'ヘルプ',
        icon: <FaQuestionCircle />,
        links: [
            {
                title: 'お問い合わせ',
                externalLink: 'https://ws.formzu.net/fgen/S97851601/',
                icon: <span />,
            },
            {
                title: 'マニュアル',
                externalLink:
                    'https://www.saats.jp/join/saatscommerce/manual/e_lister.html',
                icon: <span />,
            },
        ],
    },
];
const adminItems = [
    {
        title: 'アプリの切り替え',
        icon: <FaExchangeAlt style={{ color: 'rgba(229,229,229,255)' }} />,
        links: [
            {
                title: 'E-LISTER',
                newTab: false,
                externalLink: 'https://e-lister.saatscommerce.jp/',
                icon: (
                    <Image
                        src="/saats_e_lister.png"
                        style={{ marginRight: '0px' }}
                    />
                ),
            },
            {
                title: 'O-MANAGER',
                newTab: false,
                externalLink: 'https://order-manager.saatscommerce.jp/',
                icon: (
                    <Image
                        src="/saats_o_manager.png"
                        style={{ marginRight: '0px' }}
                    />
                ),
            },
            {
                title: 'Y-LISTER',
                newTab: false,
                externalLink: 'https://y-lister.saatscommerce.jp/',
                icon: (
                    <Image src="/y_lister.ico" style={{ marginRight: '0px' }} />
                ),
            },
        ],
    },
    {
        title: '管理',
        icon: <FaCog style={{ color: 'rgba(229,229,229,255)' }} />,
        links: [
            {
                title: 'ストア管理',
                link: '/home',
                icon: <span />,
            },
            {
                title: 'Admin',
                link: '/admin',
                icon: (
                    <MdOutlineAdminPanelSettings
                        style={{
                            color: 'rgba(229, 229, 229, 255',
                            marginRight: '4px',
                        }}
                    />
                ),
            },
        ],
    },
    {
        title: 'ヘルプ',
        icon: <FaQuestionCircle />,
        links: [
            {
                title: 'お問い合わせ',
                externalLink: 'https://ws.formzu.net/fgen/S97851601/',
                icon: <span />,
            },
            {
                title: 'マニュアル',
                externalLink:
                    'https://www.saats.jp/join/saatscommerce/manual/e_lister.html',
                icon: <span />,
            },
        ],
    },
];
export const SideBar: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
    const { isAdmin } = useContext(AccountContext);
    const [items, setItems] = useState([...initItems]);
    useEffect(() => {
        if (isAdmin) {
            setItems([...adminItems]);
        } else {
            setItems([...initItems]);
        }
    }, [isAdmin]);
    const [brand, setBrand] = useState<JSX.Element>(
        <Navbar.Brand
            style={{
                marginTop: '8px',
                marginBottom: '30px',
                marginLeft: '20px',
            }}
        >
            <Image
                style={{ width: '192px', height: '38px' }}
                src="/s-research-LOGO.png"
            />
        </Navbar.Brand>,
    );
    useEffect(() => {
        if (collapsed) {
            setBrand(
                <Navbar.Brand style={{}}>
                    <Image
                        style={{
                            position: 'relative',
                            left: '19px',
                            top: '12px',
                            width: '37px',
                            height: '38px',
                        }}
                        src="/s-research-LOGO-collapsed.png"
                    />
                </Navbar.Brand>,
            );
        } else {
            setBrand(
                <Navbar.Brand
                    style={{
                        marginTop: '8px',
                        marginBottom: '30px',
                        marginLeft: '20px',
                    }}
                >
                    <Image
                        style={{
                            position: 'relative',
                            top: '12px',
                            width: '192px',
                            height: '38px',
                        }}
                        src="/s-research-LOGO.png"
                    />
                </Navbar.Brand>,
            );
        }
    }, [collapsed]);
    return (
        <ProSidebar
            collapsed={collapsed}
            width="230px"
            collapsedWidth="70px"
            style={{
                height: '100vh',
                width: '100%',
                fontFamily: 'Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif',
            }}
        >
            <SidebarHeader style={{ width: 'auto', height: '57px' }}>
                {brand}
            </SidebarHeader>
            <SidebarContent style={{ marginTop: '19px' }}>
                <Menu iconShape="square" subMenuBullets={!collapsed}>
                    {items.map((item, index) => (
                        <SubMenu
                            id="sub-menu"
                            key={index.toString()}
                            title={item.title}
                            icon={item.icon}
                            className={
                                collapsed ? 'isCollapsed' : 'notHasCollapsed'
                            }
                        >
                            {item.links.map(
                                (
                                    link: {
                                        title: string;
                                        externalLink?: string;
                                        link?: string;
                                        icon: JSX.Element;
                                        newTab?: boolean;
                                    },
                                    index: number,
                                ) => (
                                    <MenuItem
                                        key={index.toString()}
                                        id="inner-item"
                                        icon={link.icon}
                                    >
                                        {link.title}
                                        {link.link && (
                                            <NavLink to={link.link}>
                                                <span />
                                            </NavLink>
                                        )}
                                        {link.externalLink && (
                                            <a
                                                href={link.externalLink}
                                                rel="noreferrer"
                                                target={
                                                    link.newTab === false
                                                        ? ''
                                                        : '_blank'
                                                }
                                            />
                                        )}
                                    </MenuItem>
                                ),
                            )}
                        </SubMenu>
                    ))}
                </Menu>
            </SidebarContent>
        </ProSidebar>
    );
};
