import React from 'react';
import { Row } from 'react-bootstrap'
import {NavLink} from 'react-router-dom';

const NotFoundPage: React.FC = () => {
    return (
        <Row>
            <Row>
                <Row>
                    <h1 style={
                        {
                            fontFamily: 'sans-serif',
                            fontSize: '186px',
                            fontWeight: 900,
                        }}>404</h1>
                </Row>
                <h2>Oops! This Page Could Not Be Found</h2>
                <p>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>
                <NavLink to='/'>Go to Homepage</NavLink>
            </Row>
        </Row>
    );
}

export default NotFoundPage;
