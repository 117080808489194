import axios, { AxiosInstance } from 'axios';

class Api {
    private _instance: AxiosInstance;
    constructor(baseURL: string) {
        this._instance = axios.create({
            baseURL: baseURL,
            withCredentials: true,
        });
    }
    public async get<Request, Response>(
        url: string,
        params?: Request,
    ): Promise<
        [
            Response | null,
            { data: { statusCode: number; message: string } } | null,
        ]
    > {
        try {
            const response = await this._instance.get<
                Request,
                { data: Response }
            >(url, { params: params });
            return [response.data, null];
        } catch (error: any) {
            return [null, error?.response || error];
        }
    }
    public async post<Request, Response>(
        url: string,
        data?: Request,
    ): Promise<
        [
            Response | null,
            { data: { statusCode: number; message: string } } | null,
        ]
    > {
        try {
            const response = await this._instance.post<
                Request,
                { data: Response }
            >(url, data);
            return [response.data, null];
        } catch (error: any) {
            return [null, error?.response || error];
        }
    }
    public async put<Request, Response>(
        url: string,
        data?: Request,
    ): Promise<
        [any | null, { data: { statusCode: number; message: string } } | null]
    > {
        try {
            const response = await this._instance.put<
                Request,
                { data: Response }
            >(url, data);
            return [response.data, null];
        } catch (error: any) {
            return [null, error?.response || error];
        }
    }
    public async patch<Request, Response>(
        url: string,
        data?: Request,
    ): Promise<
        [any | null, { data: { statusCode: number; message: string } } | null]
    > {
        try {
            const response = await this._instance.patch<
                Request,
                { data: Response }
            >(url, data);
            return [response.data, null];
        } catch (error: any) {
            return [null, error?.response || error];
        }
    }
    public async delete<Request, Response>(
        url: string,
        data?: Request,
    ): Promise<
        [
            Response | null,
            { data: { statusCode: number; message: string } } | null,
        ]
    > {
        try {
            const response = await this._instance.delete<
                Request,
                { data: Response }
            >(url, { data: data });
            return [response.data, null];
        } catch (error: any) {
            return [null, error?.response || error];
        }
    }
}

export default Api;
