import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Navigation } from '../nav/Navigation';
import { SideBar } from '../sidebar/Sidebar';
import { FaBars } from 'react-icons/fa';

export const Layout: React.FC<
    React.PropsWithChildren<{ collapsedWidth: number; noDisplayWidth: number }>
> = (
    props: React.PropsWithChildren<{
        collapsedWidth: number;
        noDisplayWidth: number;
    }>,
) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [canCollapse, setCanCollapse] = useState<boolean>(true);
    const [removeDisplay, setRemoveDisplay] = useState<boolean>(false);
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });
    useEffect(() => {
        const handleResize = () =>
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    });
    useEffect(() => {
        if (dimensions.width < props.collapsedWidth) {
            if (canCollapse) {
                setCollapsed(true);
                setCanCollapse(false);
            }
        } else {
            if (!canCollapse) {
                setCanCollapse(true);
            }
        }
        if (dimensions.width < props.noDisplayWidth) {
            if (!removeDisplay) {
                setRemoveDisplay(true);
            }
        } else {
            if (removeDisplay) {
                setRemoveDisplay(false);
            }
        }
    }, [dimensions]);
    return (
        <Container
            fluid
            style={{
                height: '1800px',
                fontFamily: 'Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif',
            }}
        >
            <Row style={{ height: '100%' }}>
                <Col
                    style={{
                        display: removeDisplay ? 'none' : 'block',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        backgroundColor: 'rgba(42,63,84,255)',
                        minWidth: collapsed ? '70px' : '230px',
                        maxWidth: collapsed ? '70px' : '230px',
                    }}
                >
                    <SideBar collapsed={collapsed} />
                </Col>
                <Col style={{ backgroundColor: 'rgba(247, 247, 247, 255)' }}>
                    <Row>
                        <Navigation>
                            {canCollapse && (
                                <FaBars
                                    style={{
                                        width: '22px',
                                        height: '26px',
                                        marginLeft: '3px',
                                        color: 'rgba(90,115,142,255)',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => setCollapsed(!collapsed)}
                                />
                            )}
                        </Navigation>
                    </Row>
                    <Row>{props.children}</Row>
                </Col>
            </Row>
        </Container>
    );
};
