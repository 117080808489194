import React from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumb } from 'react-bootstrap';
import { strings } from '../../locales/locales';

interface StoreBreadCrumbsProps {
    storeId: string;
}

const StoreBreadCrumbs: React.FC<StoreBreadCrumbsProps> = (props) => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item as={NavLink} active to="/home">
                {strings.accountBreadCrumb}
            </Breadcrumb.Item>
            <Breadcrumb.Item as={NavLink} active to={`/home/${props.storeId}`}>
                {props.storeId}
            </Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default StoreBreadCrumbs;
