import React from 'react';

interface EndDateProps {
    endTime?: string;
}

const EndDate: React.FC<EndDateProps> = (props) => {
    if (!props.endTime) {
        return <span>Not Found</span>;
    }
    const date = new Date(props.endTime);
    return (
        <span>
            {`${date.toISOString().split('T')[0]} ${date
                .toISOString()
                .split('T')[1]
                .substr(0, 5)} UTC`}
        </span>
    );
};

export default EndDate;
