import React, { useState } from 'react';
import { Form, Button, Row, Col, InputGroup, Spinner } from 'react-bootstrap';
import { AiOutlineSearch } from 'react-icons/ai';
import { TiDelete } from 'react-icons/ti';
import Tags from './Tags';
import { defaultFilters } from './FiltersCard.consts';
import { Filters } from './FiltersCard.types';
import { Filters as ApiFilters } from 'apis';

interface FiltersCardProps {
    isLoading: boolean;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setFilters: React.Dispatch<React.SetStateAction<ApiFilters>>;
    search: (
        filters: Filters,
        hasUpc: boolean,
        hasAsin: boolean,
        includeKeywords: string[],
        excludedKeywords: string[],
        setPage: React.Dispatch<React.SetStateAction<number>>,
        setFilters: React.Dispatch<React.SetStateAction<ApiFilters>>,
    ) => void;
    clear: (
        setPage: React.Dispatch<React.SetStateAction<number>>,
        setFilters: React.Dispatch<React.SetStateAction<ApiFilters>>,
    ) => void;
}

const FiltersCard: React.FC<FiltersCardProps> = (props) => {
    const [filters, setFilters] = useState<Filters>({
        ...defaultFilters,
    });
    const [hasUpc, setHasUpc] = useState<boolean>(false);
    const [hasAsin, setHasAsin] = useState<boolean>(false);
    const [includeKeywords, setIncludeKeywords] = useState<string[]>([]);
    const [excludeKeywords, setExcludeKeywords] = useState<string[]>([]);
    const setFilter = (e: { target: { name: string; value: string } }) => {
        const tempFilters = { ...filters };
        tempFilters[e.target.name] = e.target.value;
        setFilters(tempFilters);
    };
    return (
        <Form
            style={{
                fontFamily: 'Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif',
            }}
        >
            <Row>
                <Row className="mb-2">
                    {/*<Col>
                        <Form.Label>入札数</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                name="numberOfBidsLow"
                                onChange={(e) => setFilter(e)}
                                value={filters['numberOfBidsLow']}
                            ></Form.Control>
                            <InputGroup.Text>-</InputGroup.Text>
                            <Form.Control
                                type="text"
                                name="numberOfBidsHigh"
                                value={filters['numberOfBidsHigh']}
                                onChange={(e) => setFilter(e)}
                            ></Form.Control>
                        </InputGroup>
        </Col>*/}
                    <Col>
                        <Col>
                            <Form.Label>落札価格($)</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    name="contractPriceLow"
                                    value={filters['contractPriceLow']}
                                    onChange={(e) => setFilter(e)}
                                ></Form.Control>
                                <InputGroup.Text>-</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    name="contractPriceHigh"
                                    value={filters['contractPriceHigh']}
                                    onChange={(e) => setFilter(e)}
                                ></Form.Control>
                            </InputGroup>
                        </Col>
                    </Col>
                    <Col>
                        <Form.Label>終了時間</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="date"
                                name="endTimeStart"
                                value={filters['endTimeStart']}
                                onChange={(e) => setFilter(e)}
                            ></Form.Control>
                            <InputGroup.Text>-</InputGroup.Text>
                            <Form.Control
                                type="date"
                                name="endTimeEnd"
                                value={filters['endTimeEnd']}
                                onChange={(e) => setFilter(e)}
                            ></Form.Control>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        <Form.Group>
                            <Form.Label>アイテムID</Form.Label>
                            <Form.Control
                                type="text"
                                name="itemId"
                                value={filters['itemId']}
                                onChange={(e) => setFilter(e)}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={1} style={{ maxWidth: '140px' }}>
                        <Form.Group className="mt-4">
                            <Form.Check
                                type="checkbox"
                                label="UPCあり"
                                checked={hasUpc}
                                onChange={() => setHasUpc(!hasUpc)}
                            />
                            <Form.Check
                                type="checkbox"
                                label="ASINあり"
                                checked={hasAsin}
                                onChange={() => setHasAsin(!hasAsin)}
                            />
                        </Form.Group>
                    </Col>
                    <Col
                        xs={2}
                        style={{
                            maxWidth: '343px',
                            minWidth: '343px',
                            marginTop: '2px',
                        }}
                    >
                        <Form.Group>
                            <Form.Label
                                style={{
                                    marginBottom: '5px',
                                    fontFamily:
                                        'Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif',
                                }}
                            >
                                除外キーワード
                            </Form.Label>
                            <Tags
                                placeholder="こちらに除外したいキーワードを入力してください"
                                addTags={setExcludeKeywords}
                                mytags={excludeKeywords}
                            />
                        </Form.Group>
                    </Col>
                    <Col
                        xs={2}
                        style={{
                            maxWidth: '343px',
                            minWidth: '343px',
                            marginTop: '2px',
                        }}
                    >
                        <Form.Group>
                            <Form.Label
                                style={{
                                    marginBottom: '5px',
                                    fontFamily:
                                        'Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif',
                                }}
                            >
                                キーワード検索
                            </Form.Label>
                            <Tags
                                placeholder="こちらに検索したいキーワードを入力してください"
                                addTags={setIncludeKeywords}
                                mytags={includeKeywords}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col xs={10}>
                        <Button
                            disabled={props.isLoading}
                            variant="info"
                            style={{
                                backgroundColor: 'rgba(79,164,223,255)',
                                borderColor: 'rgba(79,164,223,255)',
                                color: 'white',
                            }}
                            onClick={() =>
                                props.search(
                                    filters,
                                    hasUpc,
                                    hasAsin,
                                    includeKeywords,
                                    excludeKeywords,
                                    props.setPage,
                                    props.setFilters,
                                )
                            }
                        >
                            {props.isLoading ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: '6px' }}
                                />
                            ) : (
                                <AiOutlineSearch />
                            )}
                            検索
                        </Button>
                        <Button
                            disabled={props.isLoading}
                            variant="danger"
                            style={{
                                backgroundColor: 'rgba(236,98,84,255)',
                                borderColor: 'rgba(236,98,84,255)',
                            }}
                            onClick={() => {
                                setFilters(defaultFilters);
                                setHasAsin(false);
                                setHasUpc(false);
                                setIncludeKeywords([]);
                                setExcludeKeywords([]);
                                props.clear(props.setPage, props.setFilters);
                            }}
                        >
                            {props.isLoading ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: '6px' }}
                                />
                            ) : (
                                <TiDelete />
                            )}
                            クリア
                        </Button>
                    </Col>
                </Row>
            </Row>
        </Form>
    );
};

export default FiltersCard;
